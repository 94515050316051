import React from 'react';
import WaitlistSearchHandler from './WaitlistSearchHandler';

const WaitlistSearch = ({ component: Component, ...props }) => {
  return (
    <WaitlistSearchHandler
      {...props}
      render={waitlistSearchState => (
        <Component {...props} {...waitlistSearchState} />
      )}
    />
  );
};

export default WaitlistSearch;
