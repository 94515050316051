import React from 'react';
import { Link } from 'react-router-dom';
import EmptyState from 'components/EmptyState';
import Loader from 'react-loader-spinner';
import WaitlistCard from './WaitlistCard';

const WaitlistCards = ({
  joinedWaitlists,
  formattedResults,
  applicantId,
  handleAlert,
  fetchUserHandler,
  showPropertySummaries,
  loading,
}) => {
  if (loading === true) {
    return (
      <EmptyState>
        <h3>
          <Loader
            className="loader-container"
            type="Oval"
            color="#00BFFF"
            height={22}
            width={22}
          />
          <span>Loading Waitlists...</span>
        </h3>
      </EmptyState>
    );
  }

  if (formattedResults.length === 0) {
    return (
      <EmptyState>
        <h3>
          We were unable to match you to any waitlists. Head over to{' '}
          <Link to="/profile">your profile</Link> to confirm that your
          information is up-to-date.
        </h3>
      </EmptyState>
    );
  }

  const joinedWaitlistData = joinedWaitlists.reduce(
    (
      acc,
      {
        id,
        attributes: {
          waitlist_id,
          relative_waitlist_position,
          date_joined,
          status,
        },
      }
    ) => ({
      ...acc,
      [waitlist_id]: {
        waitlistApplicantId: id,
        position: relative_waitlist_position,
        dateJoined: date_joined,
        status: status,
      },
    }),
    {}
  );

  const joinedWaitlistIds = Object.keys(joinedWaitlistData);
  const cardData = formattedResults.map(waitlistData => {
    const { id } = waitlistData.waitlist;
    const joined = joinedWaitlistIds.includes(id);

    return {
      key: id,
      waitlistData,
      applicantId,
      handleAlert,
      joined,
      fetchUserHandler,
      showPropertySummaries,
      waitlistApplicantId: joined && joinedWaitlistData[id].waitlistApplicantId,
      waitlistPosition: joined && joinedWaitlistData[id].position,
      waitlistStatus: joined && joinedWaitlistData[id].status,
      dateJoined: joined && joinedWaitlistData[id].dateJoined,
    };
  });

  return (
    <React.Fragment>
      {!applicantId && (
        <h3 className="text-center">
          To join a waitlist, please <a href="/login">log in</a> or{' '}
          <a href="/create-account">create an account</a>.
        </h3>
      )}
      {cardData.map(cardProps => (
        <WaitlistCard {...cardProps} />
      ))}
    </React.Fragment>
  );
};
export default WaitlistCards;
