import React from 'react';
import moment from 'moment';
import { UserProfileContext } from 'screens/Profile/Data/Provider';
import FormCard from './Form/FormCard';

const ProfileCard = () => {
  return (
    <UserProfileContext.Consumer>
      {userContext =>
        !userContext.isSet ? null : (
          <FormCard containerClasses="rounded-0">
            <h2>{userContext.user.full_name}</h2>
            <p>
              {userContext.user.email}<br/>
              {userContext.user.phone_number}
            </p>
            <ul className="list-unstyled">
              <li>
                <strong>Age:</strong>{' '}
                {moment().diff(userContext.user.date_of_birth, 'years')}
              </li>
              <li>
                <strong>Household Size:</strong>{' '}
                {userContext.user.household_size}
              </li>
              <li>
                <strong>Preferred Bedroom Sizes:</strong>{' '}
                {userContext.user.preferred_bedroom_sizes
                  ? userContext.user.preferred_bedroom_sizes
                      .map(size => size.label)
                      .join(', ')
                  : 'None Selected'}
              </li>
              <li>
                <strong>Monthly Income:</strong> $
                {userContext.user.household_income}
              </li>
              <li>
                <strong>
                  Only show me housing for (check all that apply):
                </strong>{' '}
                <p className="mb-0">
                  Senior (55+): {userContext.user.senior_citizen ? 'Yes' : 'No'}
                </p>
                <p className="mb-0">
                  Disabled: {userContext.user.disabled ? 'Yes' : 'No'}
                </p>
                <p className="mb-0">
                  Experiencing homelessness:{' '}
                  {userContext.user.homeless ? 'Yes' : 'No'}
                </p>
              </li>
              <li>
                <strong>Preferred Locations:</strong>{' '}
                {userContext.user.locations
                  ? userContext.user.locations.map(location => (
                      <p key={location.id} className="mb-0">
                        {location.attributes.long_name}
                      </p>
                    ))
                  : ''}
              </li>
              <li>
                <strong>Profile Last Updated:</strong>{' '}
                {moment(userContext.user.profile_updated_at).format('L LT')}
              </li>
            </ul>
          </FormCard>
        )
      }
    </UserProfileContext.Consumer>
  );
};

export default ProfileCard;
