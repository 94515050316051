import React from 'react';

const PropertyBuildingInfo = props => {
  const { attributes, relationships, locations } = props;

  const locationString = relationships.locations.data
    .reduce((acc, loc) => {
      const id = loc.id;
      const locData = locations.find(locationMatch => {
        return locationMatch.id === id;
      });
      acc.push(locData.attributes.short_name);
      return acc;
    }, [])
    .join(', ');

  return (
    <div>
      <h4 className="font-weight-normal mb-0">{attributes.name}</h4>
      {attributes.scattered_site ? (
        <h4 className="font-weight-normal">
          Multiple sites in {locationString}
        </h4>
      ) : (
        <h4 className="font-weight-normal">
          {attributes.street_address}
          <br />
          {attributes.city}, {attributes.state} {attributes.zip_code}
          <br />
          {attributes.county}
        </h4>
      )}
      <h4>{attributes.website}</h4>
      <h4 className="mt-4">
        Building Types:{' '}
        <span className="font-weight-normal">
          {attributes.building_types.join(', ')}
        </span>
      </h4>
      <h4 className="mt-4">
        Total Units in Property:{' '}
        <span className="font-weight-normal">{attributes.total_units}</span>
      </h4>
      <h4 className="mt-4">
        Bedroom Sizes:{' '}
        <span className="font-weight-normal">
          {attributes.unit_groups.join(', ')}
        </span>
      </h4>
    </div>
  );
};

export default PropertyBuildingInfo;
