import React from 'react';
import PropTypes from 'prop-types';
import FormHandler from './FormHandler';

const Form = ({ component: Component, ...props }) => {
  return (
    <FormHandler
      {...props}
      render={formState => (
        <Component
          {...props}
          {...formState}
          update={e =>
            formState.change(e, props.formKey || null, props.updater)
          }
        />
      )}
    />
  );
};

Form.propTypes = {
  formToEvaluate: PropTypes.object.isRequired,
  updater: PropTypes.func.isRequired,
  defaultForm: PropTypes.object,
  formKey: PropTypes.string
};

export default Form;
