import React from 'react';

import UserNamePasswordForm from 'screens/CreateAccount/Form/UserNamePassword';
import FormCard from 'components/Form/FormCard';
import Form from 'components/Form/Form';

const ClaimAccountFields = ({ profile }) => {
  return !profile.isSet ? null : (
    <FormCard>
      <Form
        formToEvaluate={profile.user}
        defaultForm={profile.user}
        updater={profile.updateValue}
        component={UserNamePasswordForm}
      />
    </FormCard>
  );
};
export default ClaimAccountFields;
