import React from 'react';
import Fields from 'components/Form/Fields';

const waitlistNotificationTypes = [
  {
    name: 'waitlist_position_notifications',
    input: 'input',
    type: 'checkbox',
    options: [
      {
        value: true,
        label:
          'I want to receive periodic notifications on my waitlist position',
      },
    ],
    containerClasses: ['form-group', 'col', 'text-md-left', 'text-center'],
    classes: ['custom-control', 'custom-checkbox'],
    describedBy: 'waitlist_position_notifications',
  },
  {
    name: 'new_waitlist_notifications',
    input: 'input',
    type: 'checkbox',
    options: [
      {
        value: true,
        label: 'I want to receive notifications about new waitlists I can join',
      },
    ],
    containerClasses: ['form-group', 'col', 'text-md-left', 'text-center'],
    classes: ['custom-control', 'custom-checkbox'],
    describedBy: 'new_waitlist_notifications-true',
  },
];

const NotificationPreferences = props => {
  return (
    <React.Fragment>
      <h2 className="text-center">Your Notifications & Messages</h2>
      <div className="row">
        <div className="col-8 offset-2 px-0 px-sm-3">
          <div className="mt-3 text-center">
            <Fields inputs={waitlistNotificationTypes} {...props} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NotificationPreferences;
