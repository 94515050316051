import React, { Component } from 'react';
import { handleJSONResponse, apiURL } from 'utils/api';
import { withRouter } from 'react-router-dom';
import { waitlist } from 'actions';
import { groupWaitlistResultsDataByType } from 'utils/waitlistSearch';
import WaitlistPage from '../../components/Waitlists/WaitlistPage';

class WaitlistLookup extends Component {
  constructor(props) {
    super();

    this.state = {
      waitlistData: {},
      handleAlert: props.handleAlert,
      applicantId: props.applicantId,
      fetchUserHandler: props.fetchUserHandler,
    };
  }

  componentDidMount() {
    this.retrieveWaitlistData();
  }

  applicantJoinedWaitlist() {
    const matchedWaitlist = this.props.joinedWaitlists.filter(waitlist => {
      return (
        waitlist.attributes.waitlist_id.toString() === this.props.waitlistId
      );
    });

    if (matchedWaitlist.length > 0) {
      return true;
    }
    return false;
  }

  joinWaitlist() {
    waitlist.handleJoinWaitlist(this.state);
  }

  handleGoBack = () => {
    this.props.history.goBack();
  };

  retrieveWaitlistData() {
    fetch(apiURL(`/waitlists/${this.props.waitlistId}`), {
      method: 'GET',
    })
      .then(resp => handleJSONResponse(resp))
      .then(resp => {
        const formattedData = groupWaitlistResultsDataByType(resp, 'waitlist');
        this.setState({ waitlistData: formattedData });
      })
      .catch(err => {
        let msg = '';
        if (err.errors) {
          msg = err.errors.map(e => e).join(', ');
        } else {
          msg = 'Sorry, something went wrong.';
        }

        this.props.handleAlert({
          show: true,
          extraClasses: ['text-white', 'text-center'],
          dismissable: true,
          removeAlert: () => this.props.handleAlert({}),
          msg,
          type: 'danger',
        });
      });
  }

  render() {
    return (
      <WaitlistPage
        waitlistData={{
          ...this.state.waitlistData,
          waitlistId: this.props.waitlistId,
        }}
        handleAlert={this.props.handleAlert}
        applicantId={this.props.applicantId}
        applicantHouseholdSize={this.props.applicantHouseholdSize}
        applicantPreferences={this.props.applicantPreferences}
        handleGoBack={this.handleGoBack}
        joined={this.applicantJoinedWaitlist()}
        joinWaitlist={this.joinWaitlist.bind(this)}
        goBack={this.handleGoBack}
      />
    );
  }
}

export default withRouter(WaitlistLookup);
