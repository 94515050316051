import React from 'react';
import Page from 'components/Page';
import { AuthContext } from 'screens/Login/Data/Provider';
import {
  AccountContext,
  requiredCreateAccountFields,
  requiredIntakeFields,
} from './Data/Provider';
import FullPageMessage from 'components/Alert/FullPageMessage';
import CreateAccountStart from './Container.1';
import { validate } from 'utils/forms';
import ConfirmAddressModal from 'screens/Profile/ConfirmAddressModal';

class CreateAccount extends React.Component {
  state = {
    verifyFieldsModal: false,
    showMissingFields: false,
  };

  openModal = () => {
    this.setState({
      verifyFieldsModal: true,
    });
  };

  closeModal = () => {
    this.setState({
      verifyFieldsModal: false,
    });
  };

  handleSubmit = accountContext => {
    if (this.canSubmit(accountContext)) {
      this.openModal();
    } else {
      this.setState({
        showMissingFields: true,
      });
    }
  };

  canSubmit = accountContext =>
    validate(accountContext, {
      ...requiredCreateAccountFields,
      ...requiredIntakeFields,
    }) &&
    accountContext.email === accountContext.confirm_email &&
    accountContext.phone_number === accountContext.confirm_phone_number;

  render() {
    return (
      <AuthContext.Consumer>
        {authContext =>
          authContext.loggedIn ? (
            this.props.history.goBack()
          ) : (
            <AccountContext.Consumer>
              {accountContext =>
                accountContext.registrationReceived ? (
                  <FullPageMessage {...accountContext.msg} />
                ) : (
                  <Page>
                    <CreateAccountStart
                      account={accountContext}
                      requiredFields={{
                        ...requiredCreateAccountFields,
                        ...requiredIntakeFields,
                      }}
                      showMissingFields={this.state.showMissingFields}
                    />

                    <div className="row">
                      <div className="col d-flex flex-column align-items-center text-center mt-2">
                        <button
                          className="btn btn-green px-5 my-4"
                          disabled={
                            this.state.showMissingFields &&
                            !this.canSubmit(accountContext)
                          }
                          onClick={() => this.handleSubmit(accountContext)}
                        >
                          Submit
                        </button>

                        {this.state.showMissingFields &&
                          !this.canSubmit(accountContext) && (
                            <p className="form-invalid-msg">
                              Some required fields are missing.
                            </p>
                          )}

                        <p>
                          Please Note: All rental housing represented within
                          Waitlist Central are available through waitlists only,
                          and typical waiting times are from several months to a
                          year or more. If you have immediate housing needs,
                          please visit HousingLink or other rental housing
                          search options.
                        </p>

                        {this.state.verifyFieldsModal && (
                          <ConfirmAddressModal
                            user={accountContext}
                            close={this.closeModal}
                            accept={accountContext.createUser}
                          />
                        )}
                      </div>
                    </div>
                  </Page>
                )
              }
            </AccountContext.Consumer>
          )
        }
      </AuthContext.Consumer>
    );
  }
}

export default CreateAccount;
