import React, { Component } from 'react';
import Page from 'components/Page';
import { AccountContext } from 'screens/CreateAccount/Data/Provider';
import { AuthContext } from 'screens/Login/Data/Provider';
import { UserProfileContext } from 'screens/Profile/Data/Provider';
import WaitlistSearch from 'components/Waitlists/WaitlistSearch';
import WaitlistCards from 'components/Waitlists/WaitlistCards';
import BrowseSidebar from 'components/Sidebar/Browse';
import Sidebar from 'components/Sidebar/Hub';
import { extractSearchParams } from 'utils/waitlistSearch';

class WaitlistResults extends Component {
  state = {
    numberOfResults: 0,
  };

  numberOfResultsUpdater(value) {
    this.setState({ numberOfResults: value });
  }

  render() {
    return (
      <Page>
        <div className="row my-5">
          <div className="col-4 mb-4">
            <h1>Browse Waitlists</h1>
          </div>
          <div className="col-md-8 d-flex justify-content-end align-items-center">
            <h5>
              {this.state.numberOfResults} Result
              {this.state.numberOfResults !== 1 && 's'}
            </h5>
          </div>
          <AuthContext.Consumer>
            {authContext =>
              authContext.loaded ? (
                authContext.loggedIn ? (
                  <UserProfileContext.Consumer>
                    {userContext =>
                      !userContext.isSet ? null : (
                        <React.Fragment>
                          <div className="col-md-3">
                            <Sidebar />
                          </div>
                          <div className="col-md-9">
                            <WaitlistSearch
                              searchParams={extractSearchParams(
                                userContext.user
                              )}
                              handleAlert={userContext.alertHandler}
                              joinedWaitlists={
                                userContext.user.waitlist_applicants || []
                              }
                              applicantId={authContext.user.id}
                              fetchUserHandler={userContext.fetchUserHandler}
                              component={WaitlistCards}
                              showPropertySummaries={true}
                              numberOfResultsUpdater={this.numberOfResultsUpdater.bind(
                                this
                              )}
                            />
                          </div>
                        </React.Fragment>
                      )
                    }
                  </UserProfileContext.Consumer>
                ) : (
                  <AccountContext.Consumer>
                    {accountContext => (
                      <React.Fragment>
                        <BrowseSidebar
                          searchParams={extractSearchParams(accountContext)}
                          handleResetBrowsing={
                            accountContext.handleResetBrowsing
                          }
                        />
                        <div className="col-md-9">
                          <WaitlistSearch
                            searchParams={extractSearchParams(accountContext)}
                            handleAlert={accountContext.alertHandler}
                            joinedWaitlists={[]}
                            component={WaitlistCards}
                            showPropertySummaries={true}
                            numberOfResultsUpdater={this.numberOfResultsUpdater.bind(
                              this
                            )}
                          />
                        </div>
                      </React.Fragment>
                    )}
                  </AccountContext.Consumer>
                )
              ) : null
            }
          </AuthContext.Consumer>
        </div>
      </Page>
    );
  }
}

export default WaitlistResults;
