import React from 'react';
import { Card } from '../Card';

const FormCard = props => {
  return (
    <div className="row">
      <div className={`${props.colWidth ? props.colWidth : 'col'}`}>
        <Card {...props} />
      </div>
    </div>
  );
};

export default FormCard;
