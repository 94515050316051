import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import GlobalNavigation from 'components/GlobalNavigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const icons = {
  error: 'exclamation-triangle'
};

const FullPageMessage = props => {
  return (
    <React.Fragment>
      <GlobalNavigation />
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-6 m-auto">
            <div className="card card-shadow text-center mt-4 p-4 pb-5">
              <p className="my-2">{props.msgHeader}</p>
              <h3>{props.msg}</h3>
              {props.img && <img src={props.img} alt="" className="d-block" />}
              {props.faIcon && props.faIcon.icon && (
                <FontAwesomeIcon
                  icon={icons[props.faIcon.icon]}
                  size="4x"
                  className={`align-self-center mb-4 mt-3 ${
                    props.faIcon.style
                  }`}
                />
              )}
              {Object.keys(props.cta).length > 1 && (
                <Link
                  to={props.cta.link}
                  className="btn btn-green align-self-center">
                  {props.cta.linkText}
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

FullPageMessage.propTypes = {
  msgHeader: PropTypes.string,
  msg: PropTypes.string.isRequired,
  img: PropTypes.string,
  cta: PropTypes.object,
  faIcon: PropTypes.shape({
    icon: PropTypes.oneOf(['error']),
    style: PropTypes.string
  })
};

export default FullPageMessage;
