import React from 'react';
import { Link } from 'react-router-dom';

import Page from 'components/Page';
import Form from 'components/Form/Form';
import FormCard from 'components/Form/FormCard';
import Fields from 'components/Form/Fields';

const ResetPasswordFields = props => (
  <Fields
    inputs={[
      {
        name: 'emailAddress',
        label: 'Email Address',
        input: 'input',
        type: 'email',
        placeholder: 'Email Address',
        containerClasses: ['form-group'],
        classes: ['form-control'],
        describedBy: 'emailAddress'
      }
    ]}
    {...props}
  />
);

class ResetPasswordForm extends React.Component {
  state = {
    formValues: {
      emailAddress: ''
    }
  };

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      formValues: { ...this.state.formValues, [name]: value }
    });
  };

  render() {
    const { handleSubmit } = this.props;
    const { formValues } = this.state;

    return (
      <Page>
        <div className="row mt-5 pt-5" data-testid="ResetPassword">
          <div className="col-md-6 m-auto">
            <FormCard>
              <div className="row justify-content-center py-md-5">
                <div className="col-10 col-md-8">
                  <h2 className="text-center">Forgot your password?</h2>
                  <Form
                    formToEvaluate={formValues}
                    defaultForm={formValues}
                    updater={this.handleInputChange}
                    component={ResetPasswordFields}
                    {...this.props}
                  />
                  <button
                    className="btn btn-green col-10 my-4 d-block mx-auto"
                    onClick={() => handleSubmit(formValues)}>
                    Send password reset instructions
                  </button>
                  <div className="text-center text-dark">
                    <Link to="/login">Login</Link>
                  </div>
                  <div className="text-center text-dark">
                    <Link to="/create-account">Sign up</Link>
                  </div>
                </div>
              </div>
            </FormCard>
          </div>
        </div>
      </Page>
    );
  }
}

export default ResetPasswordForm;
