import React, { Component } from 'react';
import { user } from 'actions';
import { states } from 'utils/constants';
import { formatPhoneNumber } from '../../../utils/forms';

const initialState = {
  isSet: false,
  user: {},
  alert: {},
  originalEmail: '',
  disqualifiedWaitlists: null,
};

export const UserProfileContext = React.createContext(initialState);

class UserProfileProvider extends Component {
  constructor() {
    super();

    this.state = initialState;
  }

  componentDidMount() {
    if (!this.state.isSet && this.props.user) {
      this.handleFetchUser();
    }
  }

  setUser = resp => {
    const { reqHeader, ...user } = resp;
    const {
      data: { relationships, attributes },
    } = user;

    // Remove the country code from the normalized number and format it.
    // Works well when editing the page to avoid validation errors while populating
    attributes.phone_number = attributes.phone_number
      ? formatPhoneNumber(attributes.phone_number.replace('+1', ''))
      : null;
    attributes.alt_phone_number = attributes.alt_phone_number
      ? formatPhoneNumber(attributes.alt_phone_number.replace('+1', ''))
      : null;

    const alt_contact_method = attributes.alt_phone_number
      ? { label: 'Phone', value: 'phone' }
      : attributes.alt_contact_email
      ? {
          label: 'Email',
          value: 'email',
        }
      : {
          label: 'Mail',
          value: 'mail',
        };
    // Map "included" based off relationship
    const included = Object.keys(relationships).reduce((acc, relationship) => {
      const relationshipData = relationships[relationship].data;
      let data;

      if (Array.isArray(relationshipData) && relationshipData.length > 0) {
        data = {
          [relationship]: user.included.filter(
            joined => joined.type === relationshipData[0].type
          ),
        };
      } else if (
        !Array.isArray(relationshipData) &&
        typeof relationshipData === 'object' &&
        relationshipData !== null
      ) {
        data = {
          ...user.included.find(joined => joined.id === relationshipData.id)
            .attributes,
        };
      }

      return { ...acc, ...data };
    }, {});

    // Prevent null values in form
    [attributes, included].forEach(metaSet => {
      Object.keys(metaSet).forEach(attr => {
        if (metaSet[attr] === null) {
          metaSet[attr] = '';
        }
      });
    });

    return {
      password: '',
      confirm_password: '',
      confirm_email: '',
      include_alt_contact: attributes.alt_full_name !== '' ? 'yes' : 'no',
      alt_contact_method,
      ...attributes,
      ...included,
      state: states.find(state => state.value === attributes.state),
      alt_state:
        states.find(state => state.value === attributes.alt_state) || '',
    };
  };

  handleAlert = alert => {
    this.setState({
      alert,
    });
  };

  handleFetchUser = () => {
    const {
      user: { id },
    } = this.props;

    user
      .fetchUser(id)
      .then(resp => {
        const user = this.setUser(resp);
        this.setState({
          user,
          isSet: true,
          originalEmail: user.email,
        });
      })
      .catch(msg => {
        this.handleAlert({
          show: true,
          extraClasses: ['text-white', 'text-center'],
          dismissable: true,
          removeAlert: () => this.handleAlert({}),
          msg,
          type: 'danger',
        });
      });
  };

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      user: {
        ...this.state.user,
        [name]: value,
      },
    });
  };

  handleConfirmWaitlistEligibility(values) {
    const {
      user: { id },
    } = this.props;

    user.confirmWaitlistEligibility(id, values).then(resp => {
      if (resp['results'] === true) {
        const confirmedValues = { confirm_changes: true, ...values };
        this.handleSaveUser(confirmedValues);
        this.setState({ disqualifiedWaitlists: null });
      } else {
        this.setState({ disqualifiedWaitlists: resp['waitlist_removals'] });
      }
    });
  }

  handlecloseWaitlistEligibilityModal() {
    this.setState({ disqualifiedWaitlists: null });
  }

  handleSaveUser(values) {
    const {
      user: { id },
    } = this.props;

    if (!values) {
      this.handleAlert({
        show: true,
        extraClasses: ['text-white', 'text-center'],
        dismissable: true,
        removeAlert: () => this.handleAlert({}),
        msg: 'Something went wrong. Please try again.',
        type: 'danger',
      });

      return null;
    }

    user
      .saveUser(id, values)
      .then(resp => {
        const user = this.setUser(resp);
        this.setState({
          user,
        });

        let alertMsg = 'Save successful.';
        const {
          data: { meta },
        } = resp;
        if (meta.sent_notification_email) {
          alertMsg += ' You will be receiving an email';
          if (meta.sent_notification_text) {
            alertMsg += ' and a text message';
          }
          alertMsg += ' confirming your profile changes.';
        }
        this.handleAlert({
          show: true,
          extraClasses: ['text-white', 'text-center'],
          dismissable: true,
          timeout: 5000,
          removeAlert: () => this.handleAlert({}),
          msg: alertMsg,
          type: 'success',
        });
      })
      .catch(msg => {
        this.handleAlert({
          show: true,
          extraClasses: ['text-white', 'text-center'],
          dismissable: true,
          removeAlert: () => this.handleAlert({}),
          msg,
          type: 'danger',
        });
      });
  }

  handleDeleteAltContact = e => {
    e.preventDefault();
    this.setState(
      {
        user: {
          ...this.state.user,
          alt_full_name: '',
          alt_preferred_name: '',
          alt_address_one: '',
          alt_address_two: '',
          alt_city: '',
          alt_state: '',
          alt_zip_code: '',
          alt_phone_number: '',
          alt_contact_email: '',
          alt_date_of_birth: '',
          alt_contact_method: '',
          alt_receives_text: false,
          alt_receives_mail: false,
          include_alt_contact: false,
        },
      },
      () => {
        user
          .fieldsForSubmit({ formState: this.state.user })
          .then(resp => this.handleSaveUser(resp));
      }
    );
  };

  render() {
    const cxt = {
      updateValue: event => this.handleInputChange(event),
      alertHandler: alert => this.handleAlert(alert),
      fetchUserHandler: () => this.handleFetchUser(),
      save: values => this.handleSaveUser(values),
      deleteAltContact: e => this.handleDeleteAltContact(e),
      confirmEligibility: values =>
        this.handleConfirmWaitlistEligibility(values),
      closeEligibilityModal: () => this.handlecloseWaitlistEligibilityModal(),
      ...this.state,
    };

    return (
      <div>
        <UserProfileContext.Provider value={cxt}>
          {this.props.children}
        </UserProfileContext.Provider>
      </div>
    );
  }
}

export default UserProfileProvider;
