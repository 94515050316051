import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

if (process.env.NODE_ENV !== 'test') ReactModal.setAppElement('#root');

class Modal extends PureComponent {
  render() {
    const { isOpen, children, close } = this.props;
    return (
      <ReactModal
        isOpen={isOpen}
        onRequestClose={close}
        className="Modal"
        overlayClassName="Overlay">
        {children}
      </ReactModal>
    );
  }
}

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
  close: PropTypes.func
};

export default Modal;
