import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AuthContext } from 'screens/Login/Data/Provider';
import { UserProfileContext } from 'screens/Profile/Data/Provider';
import Logo from 'components/Icon/Logo';
import { get } from 'utils/env';

const GlobalNavigation = () => {
  const isIntakePage = useLocation().pathname === '/intake';
  const isLoginPage = useLocation().pathname === '/login';
  return (
    <nav
      className="navbar navbar-expand-sm redesigned"
      style={
        isIntakePage ? { boxShadow: 'none' } : { backgroundColor: 'white' }
      }
    >
      <div className="container">
        <div className="row w-100 justify-content-between">
          <div className="col">
            <Link to="/" className="navbar-brand" aria-label="browse">
              <Logo width="250px" height="46px" color="#061919" />
            </Link>
          </div>
          <div className="col-lg-8 d-flex align-items-center justify-content-lg-end">
            <ul className="navbar-nav">
              <AuthContext.Consumer>
                {authContext =>
                  authContext.loggedIn ? (
                    <UserProfileContext.Consumer>
                      {userContext => (
                        <React.Fragment>
                          <li className="nav-item mr-3">
                            <a
                              className="nav-link btn-green btn text-white px-4"
                              href="https://housinglink.helpjuice.com/housinghub-help-for-applicants"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Help
                            </a>
                          </li>
                          {!userContext.user.incomplete_account && (
                            <li className="nav-item mr-3">
                              <Link
                                to="/hub"
                                className="nav-link btn-green btn text-white px-4"
                              >
                                My Hub
                              </Link>
                            </li>
                          )}
                          <li className="nav-item">
                            <span
                              className="nav-link btn btn-outline-secondary px-3"
                              onClick={authContext.logOutUser}
                              role="button"
                              tabIndex="0"
                              onKeyPress={authContext.logOutUser}
                            >
                              Log Out
                            </span>
                          </li>
                        </React.Fragment>
                      )}
                    </UserProfileContext.Consumer>
                  ) : (
                    <React.Fragment>
                      <li className="nav-item mr-3">
                        <Link to="/" className="nav-link px-4">
                          Applicants
                        </Link>
                      </li>
                      <li className="nav-item mr-3">
                        <a
                          className="nav-link px-4"
                          href={get('manager_app_root_url')}
                        >
                          Property Managers
                        </a>
                      </li>
                      <li className="nav-item mr-3">
                        <a
                          className="nav-link px-4"
                          href={`${get('manager_app_root_url')}/about-us`}
                        >
                          About Us
                        </a>
                      </li>
                      <li className="nav-item mr-3">
                        <a
                          className="nav-link px-4"
                          href="https://housinglink.helpjuice.com/housinghub-help-for-applicants"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Help
                        </a>
                      </li>
                      {!(isIntakePage || isLoginPage) && (
                        <li className="nav-item mr-3">
                          <Link to="/login" className="nav-link px-4">
                            Login
                          </Link>
                        </li>
                      )}
                    </React.Fragment>
                  )
                }
              </AuthContext.Consumer>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default GlobalNavigation;
