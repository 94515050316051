import React from 'react';
import PropTypes from 'prop-types';

const EmptyState = ({ containerClasses, children }) => {
  return (
    <div
      className={`empty-state-container ${
        containerClasses ? containerClasses.join(' ') : ''
      }`} data-testid="emptystate-div">
      {children}
    </div>
  );
};

EmptyState.propTypes = {
  containerClasses: PropTypes.array,
  children: PropTypes.element.isRequired
};

export default EmptyState;
