import React from 'react';
import Page from 'components/Page';
import { Link, Redirect } from 'react-router-dom';
import { AccountContext } from 'screens/CreateAccount/Data/Provider';
import Form from 'components/Form/Form';
import IntakeForm from './Form/Intake';
import FormCard from 'components/Form/FormCard';
import { Card } from 'components/Card';
import { get } from 'utils/env';

class IntakeQuestionnaire extends React.Component {
  state = {
    formComplete: false,
  };

  handleSubmit = () => {
    this.setState({
      formComplete: true,
    });
  };

  render() {
    return (
      <Page
        header={
          <div className="jumbotron jumbotron-fluid intake-header py-3">
            <div className="container text-center">
              <div className="row">
                <div className="col-lg-9 mx-auto">
                  <h1 className="text-center">Waitlists. Simplified.</h1>
                  <h2>
                    Less paperwork, easy waitlist management, applicant
                    self-service and much more.
                  </h2>
                </div>
              </div>
            </div>
          </div>
        }
      >
        <AccountContext.Consumer>
          {accountContext =>
            accountContext.browsingEnabled && this.state.formComplete ? (
              <Redirect to="/" />
            ) : (
              <div className="row">
                <div className="col-lg-7">
                  <FormCard>
                    <h2 className="text-center mt-3 mb-5">
                      Search Available Housing
                    </h2>
                    <Form
                      formToEvaluate={accountContext}
                      updater={accountContext.updateValue}
                      component={IntakeForm}
                      hideHouseholdMembers={true}
                      {...this.props}
                    />
                    <button
                      type="button"
                      className="btn btn-green d-block mx-auto my-3 px-5"
                      onClick={this.handleSubmit}
                      disabled={!accountContext.browsingEnabled}
                    >
                      Search
                    </button>
                  </FormCard>
                </div>
                <div className="col-lg-5">
                  <Card containerClasses="px-5 text-center">
                    <h2 className="text-center">Applicants</h2>
                    <p className="font-weight-bold px-5">
                      Join waitlists from your phone, tablet, or computer.
                    </p>
                    <div className="row mb-4">
                      <div className="col-lg-5 mt-4">
                        <Link
                          to="/login"
                          className="nav-link btn btn-outline-secondary px-4"
                        >
                          Login
                        </Link>
                      </div>
                      <div className="col-lg-7 mt-4">
                        <Link
                          to="/create-account"
                          className="nav-link btn-green btn text-white px-3"
                        >
                          Create an Account
                        </Link>
                      </div>
                    </div>
                    <div className="my-4">
                      <a
                        className="btn btn-blue w-50"
                        href="https://housinglink.helpjuice.com/housinghub-help-for-applicants"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Help
                      </a>
                    </div>
                  </Card>
                  <Card containerClasses="px-5 text-center">
                    <h2 className="text-center">Property Managers</h2>

                    <div className="my-4">
                      <p className="font-weight-bold px-5">
                        Save time on waitlist management and filling openings.
                      </p>
                      <a
                        className="btn btn-blue w-100"
                        href={get('manager_app_root_url')}
                      >
                        Learn More
                      </a>
                    </div>
                    <div className="my-4">
                      <p className="font-weight-bold px-5">
                        Already have an existing account?
                      </p>
                      <a
                        className="btn btn-outline-secondary w-100"
                        href={get('manager_app_root_url')}
                      >
                        Login
                      </a>
                    </div>
                  </Card>
                </div>
              </div>
            )
          }
        </AccountContext.Consumer>
      </Page>
    );
  }
}

export default IntakeQuestionnaire;
