import React from 'react';
import { Link } from 'react-router-dom';
import WaitlistCards from './WaitlistCards';
import EmptyState from 'components/EmptyState';

const JoinedWaitlistCards = ({
  formattedResults,
  handleAlert,
  joinedWaitlists,
  applicantId,
  fetchUserHandler,
}) => {
  if (formattedResults.length === 0) {
    return null;
  }

  const joinedWaitlistIds = joinedWaitlists.map(waitlist =>
    waitlist.attributes.waitlist_id.toString()
  );

  const filteredWaitlists = formattedResults.reduce((acc, waitlistData) => {
    if (joinedWaitlistIds.includes(waitlistData.waitlist.id)) {
      acc.push(waitlistData);
    }
    return acc;
  }, []);

  if (filteredWaitlists.length === 0) {
    return (
      <div className="mb-5">
        <h2>My Waitlists</h2>
        <EmptyState>
          <h3>
            You have not joined any waitlists yet. Head over to{' '}
            <Link to="/browse">browse</Link> to see a full list of what you
            qualify for.
          </h3>
        </EmptyState>
      </div>
    );
  }

  return (
    <div>
      <h2>My Waitlists</h2>
      <WaitlistCards
        formattedResults={filteredWaitlists}
        applicantId={applicantId}
        handleAlert={handleAlert}
        joinedWaitlists={joinedWaitlists}
        showPropertySummaries={false}
        fetchUserHandler={fetchUserHandler}
      />
    </div>
  );
};
export default JoinedWaitlistCards;
