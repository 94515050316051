import React from 'react';

import ContactInfoForm from 'screens/CreateAccount/Form/ContactInfo';
import IntakeForm from 'screens/Intake/Form/Intake';
import PreferencesForm from 'screens/CreateAccount/Form/Preferences';
import UserNamePasswordForm from 'screens/CreateAccount/Form/UserNamePassword';
import FormCard from 'components/Form/FormCard';
import Form from 'components/Form/Form';

const CompleteAccountFields = ({ profile, ...props }) => {
  return !profile.isSet ? null : (
    <React.Fragment>
      <FormCard>
        <Form
          formToEvaluate={profile.user}
          defaultForm={profile.user}
          updater={profile.updateValue}
          component={UserNamePasswordForm}
          {...props}
        />
      </FormCard>
      <Form
        formToEvaluate={profile.user}
        defaultForm={profile.user}
        component={ContactInfoForm}
        updater={profile.updateValue}
        {...props}
      />
      <FormCard>
        <Form
          formToEvaluate={profile.user}
          defaultForm={profile.user}
          component={PreferencesForm}
          updater={profile.updateValue}
          {...props}
        />
      </FormCard>
      <FormCard>
        <Form
          formToEvaluate={profile.user}
          defaultForm={profile.user}
          updater={profile.updateValue}
          component={IntakeForm}
          {...props}
        />
      </FormCard>
    </React.Fragment>
  );
};
export default CompleteAccountFields;
