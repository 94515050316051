import React from 'react';

const WaitlistPositionTab = props => {
  const {
    waitlistPosition,
    waitlistStatus,
    dateJoined,
    removeWaitlistHandler
  } = props;

  return (
    <div
      className={`mx-3 card mb-3 waitlist-position ${
        waitlistStatus === 'Denied' ? 'removed' : ''
      }`}>
      <div className="row">
        <div className="col-md-8">
          <h4 className="pb-3 pt-4 px-4 mb-0">
            Status: <span className="font-weight-normal">{waitlistStatus}</span>
          </h4>
          <h4 className="pb-3 px-4 mb-0">
            Date Joined:{' '}
            <span className="font-weight-normal">{dateJoined}</span>
          </h4>
          {waitlistPosition && (
            <h4 className="pb-3 px-4 mb-0">
              Current Position:{' '}
              <span className="font-weight-normal">{waitlistPosition}</span>
            </h4>
          )}
        </div>
        <div className="col-md-4 d-flex align-items-center justify-content-end">
          <button className="red-link mx-4" onClick={removeWaitlistHandler}>
            Remove Me
          </button>
        </div>
      </div>
    </div>
  );
};

export default WaitlistPositionTab;
