import React, { Component } from 'react';
import { handleJSONResponse, apiURL } from 'utils/api';
import { validate } from 'utils/forms';

export const AccountContext = React.createContext();

// Required fields.
// When validating, these values will be tested for changes
export const requiredCreateAccountFields = {
  email: '',
  confirm_email: '',
  password: '',
  confirm_password: '',
  full_name: '',
  preferred_name: '',
  address_one: '',
  city: '',
  state: '',
  zip_code: '',
  phone_number: '',
  confirm_phone_number: '',
  date_of_birth: '',
};

export const requiredIntakeFields = {
  household_income: 0,
  locations: [],
  household_size: 0,
  preferred_bedroom_sizes: [],
};

class AccountProvider extends Component {
  state = {
    ...requiredCreateAccountFields,
    ...requiredIntakeFields,
    address_two: '',
    receives_text: false,
    receives_mail: true,
    alt_address_two: '',
    alt_receives_text: true,
    alt_receives_mail: true,
    alt_full_name: '',
    alt_preferred_name: '',
    alt_address_one: '',
    alt_city: '',
    alt_state: '',
    alt_zip_code: '',
    alt_phone_number: '',
    alt_contact_email: '',
    alt_date_of_birth: '',
    notification_opt_in: true,
    accessible_unit: false,
    mobility_device: false,
    domestic_violence: false,
    displaced: false,
    working_family: true,
    disabled: false,
    homeless: false,
    senior_citizen: false,
    registrationReceived: false,
    alert: {},
    browsingEnabled: false,
    include_alt_contact: false,
    alt_contact_method: { label: 'Mail', value: 'mail' },
    confirm_email: '',
    confirm_phone_number: '',
    household_members: [],
  };

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState(
      {
        [name]: value,
      },
      () => {
        const browsingEnabled = validate(
          {
            household_size: this.state.household_size,
            locations: this.state.locations,
          },
          requiredIntakeFields
        );

        this.setState({
          browsingEnabled,
        });
      }
    );
  };

  handleAlert = alert => {
    this.setState({
      alert,
    });
  };

  setRegisteredState = token => {
    this.setState({
      registrationReceived: true,
      msg: {
        msgHeader: 'Thanks!',
        msg: 'Check your email to verify your account.',
        cta: {
          link: '/login',
          linkText: 'Continue',
        },
      },
      token,
    });
  };

  handleSaveUser(values) {
    if (!values) {
      this.handleAlert({
        show: true,
        extraClasses: ['text-white', 'text-center'],
        dismissable: true,
        removeAlert: () => this.handleAlert({}),
        msg: 'Something went wrong. Please try again.',
        destroyOnRouteChange: true,
        type: 'danger',
      });

      return null;
    }

    const confirm_path =
      new URLSearchParams(window.location.search).get('returnTo') || '/login';

    values.confirm_success_url = `${window.location.origin}${confirm_path}`;

    // DEBT: Slight variation in naming of attributes object
    delete Object.assign(values, {
      applicant_info: values['applicant_info_attributes'],
    })['applicant_info_attributes'];

    fetch(apiURL('/auth'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    })
      .then(resp => handleJSONResponse(resp))
      .then(resp => this.setRegisteredState(resp.headers))
      .catch(err => {
        let msg = [
          'Sorry, there was an error with the request. Please try again later.',
        ];
        if (err.errors) {
          msg = err.errors.full_messages.join(', ');
        }

        this.handleAlert({
          show: true,
          extraClasses: ['text-white', 'text-center'],
          dismissable: true,
          removeAlert: () => this.handleAlert({}),
          msg,
          destroyOnRouteChange: true,
          type: 'danger',
        });
      });
  }

  handleResetBrowsing() {
    this.setState({
      ...requiredIntakeFields,
      disabled: false,
      homeless: false,
      senior_citizen: false,
      browsingEnabled: false,
    });
  }

  render() {
    const cxt = {
      updateValue: event => this.handleInputChange(event),
      alertHandler: alert => this.handleAlert(alert),
      createUser: values => this.handleSaveUser(values),
      handleResetBrowsing: () => this.handleResetBrowsing(),
      ...this.state,
    };

    return (
      <AccountContext.Provider value={cxt}>
        {this.props.children}
      </AccountContext.Provider>
    );
  }
}

export default AccountProvider;
