import React from 'react';

const Logo = props => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 462 73.6"
    {...props}
  >
    <g>
      <g>
        <path
          className="st0"
          fill={props.color || '#001616'}
          d="M157.2,31.1l-11.3,34.6h-8.6l-7.6-23.4l-7.9,23.4h-8.6l-11.4-34.6h8.3l7.8,24.3l8.2-24.3h7.4l7.9,24.5
			l8.1-24.5H157.2z"
        />
        <path
          className="st0"
          fill={props.color || '#001616'}
          d="M182.4,50.6v15.2h-7.2v-3.3c-1.4,2.4-4.2,3.7-8.1,3.7c-6.2,0-9.9-3.5-9.9-8.1c0-4.7,3.3-8,11.4-8h6.1
			c0-3.3-2-5.2-6.1-5.2c-2.8,0-5.7,0.9-7.7,2.5l-2.8-5.4c2.9-2.1,7.2-3.2,11.5-3.2C177.7,38.7,182.4,42.5,182.4,50.6z M174.7,57.3
			v-2.7h-5.3c-3.6,0-4.7,1.3-4.7,3.1c0,1.9,1.6,3.2,4.4,3.2C171.6,60.9,173.8,59.8,174.7,57.3z"
        />
        <path
          className="st0"
          fill={props.color || '#001616'}
          d="M185.5,31.1c0-2.4,1.9-4.3,4.8-4.3s4.8,1.8,4.8,4.2c0,2.6-1.9,4.5-4.8,4.5S185.5,33.5,185.5,31.1z
			 M186.4,39.1h7.7v26.6h-7.7V39.1z"
        />
        <path
          className="st0"
          fill={props.color || '#001616'}
          d="M215.5,64.5c-1.6,1.1-3.9,1.7-6.3,1.7c-6.3,0-9.9-3.2-9.9-9.5V45.7h-4.1v-5.9h4.1v-6.5h7.7v6.5h6.6v5.9H207
			v10.8c0,2.3,1.2,3.5,3.3,3.5c1.1,0,2.3-0.3,3.1-1L215.5,64.5z"
        />
        <path
          className="st0"
          fill={props.color || '#001616'}
          d="M216.9,29.1h7.7v36.7h-7.7V29.1z"
        />
        <path
          className="st0"
          fill={props.color || '#001616'}
          d="M227.9,31.1c0-2.4,1.9-4.3,4.8-4.3c2.9,0,4.8,1.8,4.8,4.2c0,2.6-1.9,4.5-4.8,4.5
			C229.8,35.4,227.9,33.5,227.9,31.1z M228.8,39.1h7.7v26.6h-7.7V39.1z"
        />
        <path
          className="st0"
          fill={props.color || '#001616'}
          d="M238.2,63.3l2.6-5.5c2.4,1.5,6,2.6,9.2,2.6c3.6,0,4.9-0.9,4.9-2.4c0-4.4-16.1,0.1-16.1-10.5
			c0-5,4.5-8.6,12.3-8.6c3.7,0,7.7,0.8,10.2,2.3l-2.6,5.5c-2.6-1.5-5.2-2-7.7-2c-3.5,0-4.9,1.1-4.9,2.5c0,4.5,16.1,0.1,16.1,10.6
			c0,4.9-4.6,8.5-12.6,8.5C245.2,66.1,240.6,64.9,238.2,63.3z"
        />
        <path
          className="st0"
          fill={props.color || '#001616'}
          d="M281.2,64.5c-1.6,1.1-3.9,1.7-6.3,1.7c-6.3,0-9.9-3.2-9.9-9.5V45.7h-4.1v-5.9h4.1v-6.5h7.7v6.5h6.6v5.9h-6.6
			v10.8c0,2.3,1.2,3.5,3.3,3.5c1.1,0,2.3-0.3,3.1-1L281.2,64.5z"
        />
        <path
          className="st0"
          fill={props.color || '#001616'}
          d="M291.9,48.4c0-10.4,8-17.9,18.8-17.9c6,0,11,2.2,14.2,6.1l-5.1,4.7c-2.3-2.7-5.2-4.1-8.7-4.1
			c-6.5,0-11.1,4.5-11.1,11.1s4.6,11.1,11.1,11.1c3.5,0,6.4-1.4,8.7-4.1l5.1,4.7c-3.3,4-8.3,6.2-14.3,6.2
			C300,66.3,291.9,58.9,291.9,48.4z"
        />
        <path
          className="st0"
          fill={props.color || '#001616'}
          d="M352.8,54.6h-20.1c0.7,3.3,3.6,5.3,7.6,5.3c2.8,0,4.8-0.8,6.7-2.6l4.1,4.5c-2.5,2.8-6.2,4.3-11,4.3
			c-9.2,0-15.2-5.8-15.2-13.7c0-8,6.1-13.7,14.2-13.7c7.8,0,13.8,5.2,13.8,13.8C352.9,53.1,352.8,54,352.8,54.6z M332.5,50.1h13.1
			c-0.5-3.4-3.1-5.5-6.5-5.5C335.6,44.6,333.1,46.7,332.5,50.1z"
        />
        <path
          className="st0"
          fill={props.color || '#001616'}
          d="M382.3,50.5v15.2h-7.7v-14c0-4.3-2-6.3-5.4-6.3c-3.7,0-6.4,2.3-6.4,7.2v13.2h-7.7V39.1h7.4v3.1
			c2.1-2.3,5.2-3.5,8.8-3.5C377.5,38.7,382.3,42.4,382.3,50.5z"
        />
        <path
          className="st0"
          fill={props.color || '#001616'}
          d="M403.4,64.5c-1.6,1.1-3.9,1.7-6.3,1.7c-6.3,0-9.9-3.2-9.9-9.5V45.7h-4.1v-5.9h4.1v-6.5h7.7v6.5h6.6v5.9h-6.6
			v10.8c0,2.3,1.2,3.5,3.3,3.5c1.1,0,2.3-0.3,3.1-1L403.4,64.5z"
        />
        <path
          className="st0"
          fill={props.color || '#001616'}
          d="M421.3,38.7v7.1c-0.6,0-1.1-0.1-1.7-0.1c-4.3,0-7.1,2.3-7.1,7.4v12.6h-7.7V39.1h7.4v3.5
			C414.1,40.1,417.2,38.7,421.3,38.7z"
        />
        <path
          className="st0"
          fill={props.color || '#001616'}
          d="M446.7,50.6v15.2h-7.2v-3.3c-1.4,2.4-4.2,3.7-8.1,3.7c-6.2,0-9.9-3.5-9.9-8.1c0-4.7,3.3-8,11.4-8h6.1
			c0-3.3-2-5.2-6.1-5.2c-2.8,0-5.7,0.9-7.7,2.5l-2.8-5.4c2.9-2.1,7.2-3.2,11.5-3.2C442,38.7,446.7,42.5,446.7,50.6z M439,57.3v-2.7
			h-5.3c-3.6,0-4.7,1.3-4.7,3.1c0,1.9,1.6,3.2,4.4,3.2C435.9,60.9,438.1,59.8,439,57.3z"
        />
        <path
          className="st0"
          fill={props.color || '#001616'}
          d="M450.7,29.1h7.7v36.7h-7.7V29.1z"
        />
      </g>
      <path
        className="st1"
        fill={props.housetwocolor || '#08A7CC'}
        d="M87.4,65.5v-22H100L71.7,15.3L43.4,43.6H55v22h11.3V54.4h9.3v11.1H87.4z M66.3,43.4v-9.3h9.3v9.3H66.3z"
      />
      <path
        className="st2"
        fill={props.houseonecolor || '#04A35D'}
        d="M43.4,48c-1.8,0-3.4-1.1-4.1-2.7c-0.7-1.7-0.3-3.6,1-4.8l12.1-12.1L32.6,7.3l-29,30.9h11.9v27.4h12V51.7h9.5
		v13.8h11.8V48H43.4z M36.5,40.3H27V30.2h9.5V40.3z"
      />
    </g>
  </svg>
);

export default Logo;
