import React from 'react';
import { Link } from 'react-router-dom';
import PropertyCardSummary from '../Properties/PropertyCardSummary';
import WaitlistPositionTab from './WaitlistPositionTab';
import EstimatedWait from './EstimatedWait';
import { waitlist } from 'actions';

const WaitlistCard = props => {
  const {
    waitlistData: {
      waitlist: { id, attributes },
      associatedProperties,
    },
    applicantId,
    waitlistPosition,
    waitlistStatus,
    dateJoined,
    showPropertySummaries,
    joined,
  } = props;

  const joinWaitlist = () => {
    waitlist.handleJoinWaitlist(props);
  };

  const removeWaitlist = () => {
    if (
      window.confirm(
        'Are you sure you want to remove yourself from this waitlist?'
      )
    ) {
      waitlist.handleRemoveWaitlist(props);
    }
  };

  return (
    <div>
      <div className="card p-4 mb-3 card-shadow waitlist-card">
        <div className="row">
          <div className="col-md-6">
            <h2 className="mb-0">{attributes.name}</h2>
            <div>
              <p className="mb-0 font-weight-bold">
                {attributes.waitlist_types.join(', ')}
              </p>
            </div>
            <p className="mb-0">
              <span className="font-weight-bold">Opened at:</span>{' '}
              {attributes.opened_at}
            </p>
            {attributes.closed_at && (
              <p className="mb-0">
                <span className="font-weight-bold">
                  {attributes.open ? 'Closes' : 'Closed'} at:
                </span>{' '}
                {attributes.closed_at}
              </p>
            )}
          </div>
          <div className="col-md-5 offset-md-1 mt-4">
            <div className="d-flex flex-column align-items-end justify-content-start">
              <EstimatedWait
                waitTime={`${attributes.avg_wait_time || 'N/A'} ${
                  attributes.avg_wait_time_units
                }`}
              ></EstimatedWait>
              <div className="waitlist-result-buttons row w-100 mx-0 justify-content-end">
                {applicantId && (
                  <button
                    className="btn btn-green col-lg-5"
                    onClick={joinWaitlist}
                    hidden={joined || !attributes.open}
                    disabled={joined || !attributes.open}
                  >
                    Join Waitlist
                  </button>
                )}

                {!applicantId && (
                  <Link
                    className="btn btn-green col-lg-5"
                    to={{ pathname: `/login/?returnTo=/view-waitlist/${id}` }}
                    hidden={!attributes.open}
                    disabled={!attributes.open}
                  >
                    Login to Join
                  </Link>
                )}

                <Link
                  to={{ pathname: `/view-waitlist/${id}` }}
                  className="btn btn-blue ml-1"
                >
                  View Details
                </Link>
              </div>
            </div>
          </div>
        </div>
        {showPropertySummaries && (
          <div>
            <hr />
            {associatedProperties.map(property => (
              <div key={property.id}>
                <PropertyCardSummary property={property} />
                <hr />
              </div>
            ))}
          </div>
        )}
      </div>
      {waitlistStatus && (
        <WaitlistPositionTab
          waitlistStatus={waitlistStatus}
          waitlistPosition={waitlistPosition}
          dateJoined={dateJoined}
          removeWaitlistHandler={removeWaitlist}
        />
      )}
    </div>
  );
};

export default WaitlistCard;
