import React from 'react';
import Page from 'components/Page';
import { AccountContext } from 'screens/CreateAccount/Data/Provider';
import { AuthContext } from 'screens/Login/Data/Provider';
import UserProfileProvider, {
  UserProfileContext,
} from 'screens/Profile/Data/Provider';
import WaitlistLookup from './WaitlistLookup';
import { extractUserPreferences } from 'utils/waitlistSearch';

const WaitlistDetails = props => {
  if (!props.match.params.id) {
    return null;
  }
  return (
    <AuthContext.Consumer>
      {authContext =>
        authContext.loaded ? (
          authContext.loggedIn ? (
            <UserProfileProvider user={authContext.user}>
              <UserProfileContext.Consumer>
                {userContext =>
                  !userContext.isSet ? null : (
                    <Page>
                      <WaitlistLookup
                        waitlistId={props.match.params.id}
                        handleAlert={userContext.alertHandler}
                        joinedWaitlists={
                          userContext.user.waitlist_applicants || []
                        }
                        applicantId={authContext.user.id}
                        fetchUserHandler={userContext.fetchUserHandler}
                        applicantHouseholdSize={userContext.user.household_size}
                        applicantPreferences={extractUserPreferences(
                          userContext.user
                        )}
                      />
                    </Page>
                  )
                }
              </UserProfileContext.Consumer>
            </UserProfileProvider>
          ) : (
            <Page>
              <AccountContext.Consumer>
                {accountContext => (
                  <WaitlistLookup
                    waitlistId={props.match.params.id}
                    handleAlert={accountContext.alertHandler}
                    joinedWaitlists={[]}
                    applicantId={null}
                    applicantHouseholdSize={accountContext.household_size}
                    applicantPreferences={extractUserPreferences(
                      accountContext
                    )}
                  />
                )}
              </AccountContext.Consumer>
            </Page>
          )
        ) : null
      }
    </AuthContext.Consumer>
  );
};

export default WaitlistDetails;
