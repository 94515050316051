import React from 'react';
import HousingLinkLogo from './Icon/HousingLinkLogo';
import EqualHousingIcon from './Icon/EqualHousingIcon';

const Footer = () => {
  return (
    <div className="footer text-white pt-5 pb-3">
      <div className="container">
        <div className="d-flex justify-content-center flex-column text-center">
          <p>Waitlist Central is powered by</p>
          <div className="mt-1 mb-3">
            <HousingLinkLogo width={182} />
          </div>
          <p>
            <a href="mailto:info@waitlistcentral.org">info@waitlistcentral.org</a>
            <span className="mx-2"> | </span>
            <a href="https://housinglink.org">housinglink.org</a>
          </p>
          <p>
            <a
              href="https://housinglink.helpjuice.com/housinghub-help-for-applicants"
              target="_blank"
              rel="noopener noreferrer"
            >
              Help
            </a>
          </p>
          <div className="small mt-2 d-flex align-items-center justify-content-center">
            <span>
              &copy; {new Date().getFullYear()} HousingLink. All rights
              reserved.
            </span>
            <span className="mx-1 icon">
              <EqualHousingIcon width={18} />
            </span>
            <span>Equal Housing Opportunity</span>
          </div>
          <div className="mt-4 d-flex align-items-center justify-content-center">
            <p>
              Crafted with{' '}
              <span role="img" aria-label="jsx-a11y/accessible-emoji">
                ❤️
              </span>{' '}
              by Software for Good
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
