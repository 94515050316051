import React from 'react';

export const Card = ({ children, ...props }) => {
  return (
    <div
      className={`card card-shadow mb-3 ${
        props.containerClasses ? props.containerClasses : ''
      }`}>
      <div className="p-4">{children}</div>
    </div>
  );
};
