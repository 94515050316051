import React from 'react';
import { initializeHouseholdMembers } from 'utils/fields';
import HouseholdMemberInput from './HouseholdMemberInput';

const HouseholdMembers = props => {
  const handleMemberUpdate = updatedData => {
    const { memberIndex, ...updatedMemberData } = updatedData;

    let updatedHouseholdMembers = props.formValues.household_members;
    updatedHouseholdMembers[memberIndex] = updatedMemberData;

    props.update({
      target: {
        type: 'text',
        value: updatedHouseholdMembers,
        name: 'household_members',
      },
    });
  };

  const handleMemberDelete = member => {
    const updatedMember = { ...member, _destroy: true };
    handleMemberUpdate(updatedMember);
  };

  const {
    formValues: { household_size, household_members },
  } = props;

  // 0, null, or '' (for manually added applicants needing to complete their profile)
  if (!household_size) {
    return null;
  }

  const filteredHouseholdMembers = household_members.filter(
    member => member._destroy !== true
  );
  if (filteredHouseholdMembers.length !== household_size - 1) {
    const updatedHouseholdMembers = initializeHouseholdMembers(
      household_size,
      household_members
    );
    props.update({
      target: {
        type: 'text',
        value: updatedHouseholdMembers,
        name: 'household_members',
      },
    });
    return null;
  } else {
    if (household_size < 2) {
      return null;
    }

    return (
      <div className="household-member-container form-group pb-3">
        <h5 className="text-center">
          Additional household members and their relationship to head of
          household
        </h5>
        <div className="row">
          <div className="col-md-4">Full Name</div>
          <div className="col-md-4">Relationship</div>
          <div className="col-md-3">Date of Birth</div>
          <div className="col-md-1" />
        </div>

        {household_members.map((member, i) => {
          if (member._destroy) {
            return null;
          }
          return (
            <HouseholdMemberInput
              key={member.id}
              id={member.id}
              full_name={member.full_name}
              relationship={member.relationship}
              date_of_birth={member.date_of_birth}
              memberIndex={i}
              handleMemberUpdate={handleMemberUpdate}
              handleMemberDelete={handleMemberDelete}
            />
          );
        })}
      </div>
    );
  }
};

export default HouseholdMembers;
