import React from 'react';
import PropTypes from 'prop-types';
import Input from './Input';

const Fields = props => {
  const { inputs, update, formValues, formErrors, touched, inputModifier } =
    props;

  return inputs.map(field =>
    Array.isArray(field) ? (
      <div className="form-group col" key={`${field[0].name}-group`}>
        <div className="row">
          {field.map(nestedField => (
            <Input
              key={nestedField.name}
              crop={nestedField}
              change={update}
              value={formValues[nestedField.name]}
              error={
                formErrors[nestedField.name] && formErrors[nestedField.name]
              }
              touched={touched}
              disabled={nestedField.disabled}
              beforeInput={
                inputModifier && inputModifier(nestedField.name, true)
              }
              afterInput={inputModifier && inputModifier(nestedField.name)}
            />
          ))}
        </div>
      </div>
    ) : (
      <Input
        key={field.name}
        crop={field}
        change={update}
        value={formValues[field.name]}
        error={formErrors[field.name] && formErrors[field.name]}
        touched={touched}
        disabled={field.disabled}
        beforeInput={inputModifier && inputModifier(field.name, true)}
        afterInput={inputModifier && inputModifier(field.name)}
      />
    )
  );
};

Fields.propTypes = {
  inputs: PropTypes.array.isRequired,
  update: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
  formErrors: PropTypes.object,
  touched: PropTypes.func,
  disabled: PropTypes.bool,
  beforeInput: PropTypes.func,
  afterInput: PropTypes.func,
  inputModifier: PropTypes.func,
};

export default Fields;
