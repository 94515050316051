import React from 'react';
import { NavLink } from 'react-router-dom';
import ProfileCard from 'components/ProfileCard';
import { UserProfileContext } from 'screens/Profile/Data/Provider';

const subNav = [
  {
    id: 'hub',
    pathname: '/hub',
    label: 'My Hub',
  },
  {
    id: 'browse',
    pathname: '/',
    label: 'Find More Waitlists',
  },
  {
    id: 'profile',
    pathname: '/profile',
    label: 'My Profile',
  },
  {
    id: 'documents',
    pathname: '/documents',
    label: 'Documents',
  },
  {
    id: 'notifications',
    pathname: '/notifications',
    label: 'Notifications',
  },
];

const Sidebar = () => {
  return (
    <div className="sticky sidebar">
      <UserProfileContext.Consumer>
        {userContext =>
          userContext.user.incomplete_account ? null : (
            <>
              {subNav.map(link => (
                <NavLink
                  key={link.id}
                  activeClassName="active"
                  className="nav-item nav-link btn btn-outline-secondary mb-3 pb-2"
                  to={link.pathname}
                  exact={link.pathname === '/'}
                >
                  {link.label}
                </NavLink>
              ))}
            </>
          )
        }
      </UserProfileContext.Consumer>
      <ProfileCard />
    </div>
  );
};

export default Sidebar;
