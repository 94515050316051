import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { evaluateError, evaluateKeyPress } from 'utils/forms';

class FormHandler extends Component {
  state = {
    formErrors: {},
    touchedFields: [],
  };

  handleChange = (event, form, updater) => {
    updater(evaluateKeyPress(event), form);
    return this.formCanSubmit(event);
  };

  componentDidUpdate(prevProps) {
    const { showMissingFields } = this.props;
    if (
      showMissingFields &&
      showMissingFields !== prevProps.showMissingFields
    ) {
      this.evaluateErrors();
    }
  }

  // Evaluates errors on all fields.
  evaluateErrors = () => {
    const { requiredFields, formToEvaluate } = this.props;
    const errors = {};
    for (const field in requiredFields) {
      errors[field] = evaluateError(formToEvaluate, field, requiredFields);
    }
    this.setState({
      formErrors: {
        ...errors,
        ...this.state.formErrors,
      },
    });
  };

  handleEvaluateError = field => {
    const { formToEvaluate } = this.props;

    this.setState({
      formErrors: {
        ...this.state.formErrors,
        [field]: evaluateError(
          formToEvaluate,
          field,
          this.props.requiredFields
        ),
      },
    });
  };

  handleConditionalFields = currentValues => {
    const {
      canSubmit: { test },
    } = this.props;

    // TODO: Move test up to component that needs it
    return test
      .map(
        condition =>
          currentValues[condition.field] === condition.value ||
          currentValues[condition.field] ===
            currentValues[`confirm_${condition.field}`]
      )
      .every(val => val);
  };

  handleBlur = field => {
    let touchedFields = this.state.touchedFields;
    touchedFields.push(field);
    touchedFields = [...new Set(touchedFields)]; // dedupe

    this.setState(
      {
        touchedFields,
      },
      () => {
        this.handleEvaluateError(field);
      }
    );
  };

  formCanSubmit = event => {
    const { canSubmit, formToEvaluate } = this.props;

    if (canSubmit) {
      const currentValues = {
        ...formToEvaluate,
        [event.target.name]: event.target.value,
      };

      return canSubmit.callback(this.handleConditionalFields(currentValues));
    }

    return true;
  };

  render() {
    const form = {
      touched: field => this.handleBlur(field),
      change: (event, form, updater) => this.handleChange(event, form, updater),
      formValues: this.props.formToEvaluate,
      ...this.state,
    };

    return <form>{this.props.render(form)}</form>;
  }
}

FormHandler.propTypes = {
  formToEvaluate: PropTypes.object.isRequired,
  render: PropTypes.func.isRequired,
  defaultForm: PropTypes.object,
  requireAll: PropTypes.bool,
  canSubmit: PropTypes.shape({
    test: PropTypes.array,
    callback: PropTypes.func,
  }),
};

export default FormHandler;
