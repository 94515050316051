import { handleJSONResponse, apiURL, getToken } from 'utils/api';

const handleJoinWaitlist = ({
  applicantId,
  waitlistData: { waitlist },
  fetchUserHandler,
  handleAlert
}) => {
  const JWT = getToken();

  fetch(apiURL(`/waitlist_applicants`), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...JWT.token
    },
    body: JSON.stringify({
      applicant_id: applicantId,
      waitlist_id: waitlist.id
    })
  })
    .then(resp => handleJSONResponse(resp, JWT.storageMethod))
    .then(resp => {
      let msg = resp.success;

      handleAlert({
        show: true,
        extraClasses: ['text-white', 'text-center'],
        dismissable: true,
        removeAlert: () => handleAlert({}),
        msg,
        type: 'success'
      });

      fetchUserHandler();
    })
    .catch(err => {
      let msg = ['Sorry, something went wrong.'];
      if (err.errors) {
        msg = err.errors.map(e => e).join(', ');
      }

      handleAlert({
        show: true,
        extraClasses: ['text-white', 'text-center'],
        dismissable: true,
        removeAlert: () => handleAlert({}),
        msg,
        type: 'danger'
      });
    });
};

const handleRemoveWaitlist = ({
  waitlistApplicantId,
  fetchUserHandler,
  handleAlert
}) => {
  const JWT = getToken();

  fetch(apiURL(`/waitlist_applicants/${waitlistApplicantId}/remove`), {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      ...JWT.token
    }
  })
    .then(resp => handleJSONResponse(resp, JWT.storageMethod))
    .then(resp => {
      let msg = resp.success;

      handleAlert({
        show: true,
        extraClasses: ['text-white', 'text-center'],
        dismissable: true,
        removeAlert: () => handleAlert({}),
        msg,
        type: 'success'
      });

      fetchUserHandler();
    })
    .catch(err => {
      let msg = ['Sorry, something went wrong.'];
      if (err.errors) {
        msg = err.errors.map(e => e).join(', ');
      }

      handleAlert({
        show: true,
        extraClasses: ['text-white', 'text-center'],
        dismissable: true,
        removeAlert: () => handleAlert({}),
        msg,
        type: 'danger'
      });
    });
};

const waitlist = { handleJoinWaitlist, handleRemoveWaitlist };

export default waitlist;
