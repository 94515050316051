import React, { Component } from 'react';
import { AuthContext } from 'screens/Login/Data/Provider';
import { UserProfileContext } from 'screens/Profile/Data/Provider';

import Page from 'components/Page';
import Sidebar from 'components/Sidebar/Hub';
import NotificationList from '../../components/Notifications/NotificationList';

class Notifications extends Component {
  state = {
    notifications: []
  };

  render() {
    return (
      <Page>
        <h1 className="mt-4 mb-3 pb-3">Notifications</h1>
        <div className="row">
          <div className="col-md-3">
            <Sidebar />
          </div>
          <div className="col-md-8">
            <AuthContext.Consumer>
              {authContext =>
                authContext.loaded &&
                (authContext.loggedIn && (
                  <UserProfileContext.Consumer>
                    {userContext =>
                      !userContext.isSet ? null : (
                        <NotificationList
                          applicantId={authContext.user.id}
                          handleAlert={userContext.alertHandler}
                        />
                      )
                    }
                  </UserProfileContext.Consumer>
                ))
              }
            </AuthContext.Consumer>
          </div>
        </div>
      </Page>
    );
  }
}

export default Notifications;
