export const DEVELOPMENT = {
  api_url: '', // leave blank to use the proxy set in package.json. This bypasses any CORS issues.
  basename: '/applicant_api/v1',
  manager_app_root_url: 'http://localhost:3000',
  applicant_app_root_url: 'http://localhost:3001',
};

export const STAGING = {
  api_url: `https://${
    process.env.REACT_APP_WAITLISTCENTRAL_ADMIN_DOMAIN ||
    'waitlistcentral.sfg.io'
  }`,
  basename: '/applicant_api/v1',
  manager_app_root_url: `https://${
    process.env.REACT_APP_WAITLISTCENTRAL_ADMIN_DOMAIN ||
    'waitlistcentral.sfg.io'
  }`,
  applicant_app_root_url: `https://${
    process.env.REACT_APP_WAITLISTCENTRAL_APPLICANT_DOMAIN ||
    'waitlistcentral-applicant.sfg.io'
  }`,
};

export const PRODUCTION = {
  api_url: `https://${
    process.env.REACT_APP_WAITLISTCENTRAL_ADMIN_DOMAIN ||
    'manage.waitlistcentral.org'
  }`,
  basename: '/applicant_api/v1',
  manager_app_root_url: `https://${
    process.env.REACT_APP_WAITLISTCENTRAL_ADMIN_DOMAIN ||
    'manage.waitlistcentral.org'
  }`,
  applicant_app_root_url: `https://${
    process.env.REACT_APP_WAITLISTCENTRAL_APPLICANT_DOMAIN ||
    'waitlistcentral.org'
  }`,
};
