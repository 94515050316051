import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import queryString from 'query-string';

import { AuthContext } from './Data/Provider';
import LoginForm from './Form/Login';

class Login extends React.Component {
  state = {
    token: null,
  };

  componentDidMount() {
    const { history, location } = this.props;
    const query = queryString.parse(location.search);

    if (query['access-token'] && query.client && query.uid) {
      const { client, uid } = query;
      const token = {
        'access-token': query['access-token'],
        client,
        uid,
      };

      history.replace({
        ...location,
        search: '',
      });
      this.setState({
        token,
      });
    }
  }

  render() {
    return (
      <AuthContext.Consumer>
        {authContext => {
          const { token } = this.state;

          if (authContext.loggedIn) {
            if (authContext.redirectPath) {
              return <Redirect to={authContext.redirectPath} />;
            } else {
              return <Redirect to="/hub" />;
            }
          }

          if (token) {
            authContext.logInFromToken(token).then(() => {
              this.setState({
                token: null,
              });
            });
            return null;
          }

          return (
            <LoginForm
              handleSubmit={formValues => authContext.logInUser(formValues)}
            />
          );
        }}
      </AuthContext.Consumer>
    );
  }
}

export default withRouter(Login);
