import React from 'react';
import PropTypes from 'prop-types';

const Popover = ({ trigger, direction, show, header, children }) => {
  return (
    <span className="popover-container" data-testid="popover-container">
      {trigger}
      <div
        className={`popover bs-popover-${direction ? direction : 'right'} ${
          show ? 'show' : ''
        }`}
        role="tooltip">
        <div className="arrow" />
        <h3 className="popover-header">{header}</h3>
        <div className="popover-body">{children}</div>
      </div>
    </span>
  );
};

Popover.propTypes = {
  trigger: PropTypes.element.isRequired,
  direction: PropTypes.string,
  show: PropTypes.bool.isRequired,
  header: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired
};

export default Popover;
