import React from 'react';

// WARNING changes to this file must also be made in housing_hub/app/javascript/components/Properties/PropertyImagesSection
const PropertyImagesSection = ({ propertyImages }) => {
  if (propertyImages.length === 0) {
    return null;
  }

  return (
    <div className="row my-5 justify-content-center">
      <div className="col-md-5">
        <div className="property-image-bg featured-image">
          <div className="property-image-wrapper">
            <img src={propertyImages[0].attributes.image_url} alt="property" />
          </div>
        </div>
      </div>
      <div className="col-md-5">
        <div className="row">
          {propertyImages.slice(1, 5).map(image => {
            return (
              <div key={image.id} className="col-md-6 mb-3">
                <div className="property-image-bg">
                  <div className="property-image-wrapper">
                    <img src={image.attributes.image_url} alt="property" />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PropertyImagesSection;
