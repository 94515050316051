import React from 'react';
import { Redirect } from 'react-router-dom';

import { UserProfileContext } from 'screens/Profile/Data/Provider';
import { validate } from 'utils/forms';
import Page from 'components/Page';
import CompleteAccountFields from './Fields';
import ConfirmAddressModal from 'screens/Profile/ConfirmAddressModal';
import ConfirmChangesModal from 'screens/Profile/ConfirmChangesModal';

const requiredCompleteAccountFields = {
  email: '',
  full_name: '',
  preferred_name: '',
  address_one: '',
  city: '',
  state: '',
  zip_code: '',
  phone_number: '',
  date_of_birth: '',
  household_income: 0,
  locations: [],
  household_size: 0,
  preferred_bedroom_sizes: [],
};

class CompleteAccount extends React.Component {
  state = {
    popovers: {
      deleteAltContact: false,
    },
    verifyFieldsModal: false,
    showMissingFields: false,
  };

  handleTogglePopover = (e, popover) => {
    e.preventDefault();

    this.setState(prevState => {
      return {
        popovers: {
          ...prevState.popovers,
          [popover]: !prevState.popovers[popover],
        },
      };
    });
  };

  handleSubmit = user => {
    if (this.canSubmit(user)) {
      this.openModal();
    } else {
      this.setState({
        showMissingFields: true,
      });
    }
  };

  canSubmit = user => validate(user, requiredCompleteAccountFields);

  openModal = () => {
    this.setState({
      verifyFieldsModal: true,
    });
  };

  closeModal = () => {
    this.setState({
      verifyFieldsModal: false,
    });
  };

  render() {
    return (
      <Page>
        <UserProfileContext.Consumer>
          {userContext =>
            !userContext.user.incomplete_account ? (
              <Redirect to="/hub" />
            ) : (
              <React.Fragment>
                <div className="row mb-3">
                  <div className="col-md-8 m-auto text-center">
                    <h1 className="green-text mt-5 pt-5">
                      Complete Your Account
                    </h1>
                    <h4>
                      Before you can join more waitlists you will need to
                      complete your account
                    </h4>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-9 mx-auto">
                    <CompleteAccountFields
                      profile={userContext}
                      popovers={this.state.popovers}
                      togglePopover={this.handleTogglePopover}
                      delete={(e, method) => {
                        userContext[method](e);
                        this.handleTogglePopover(e, method);
                      }}
                      requiredFields={requiredCompleteAccountFields}
                      showMissingFields={this.state.showMissingFields}
                    />
                    <div className="row mt-3 mb-5">
                      <div className="col d-flex flex-column align-items-center text-center mt-2">
                        <button
                          className="btn btn-green px-5 my-4"
                          disabled={
                            this.state.showMissingFields &&
                            !this.canSubmit(userContext.user)
                          }
                          onClick={() => this.handleSubmit(userContext.user)}
                        >
                          Submit
                        </button>
                        {this.state.showMissingFields &&
                          !this.canSubmit(userContext.user) && (
                            <p className="form-invalid-msg">
                              Some required fields are missing.
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                </div>

                {this.state.verifyFieldsModal && (
                  <ConfirmAddressModal
                    user={userContext.user}
                    accept={values => {
                      userContext.confirmEligibility({
                        ...values,
                        incomplete_account: false,
                      });
                    }}
                    close={this.closeModal}
                  />
                )}
                {userContext.disqualifiedWaitlists && (
                  <ConfirmChangesModal
                    waitlistRemovals={userContext.disqualifiedWaitlists}
                    user={userContext.user}
                    accept={values => {
                      userContext.save({
                        ...values,
                        incomplete_account: false,
                      });
                    }}
                    fetchUserHandler={userContext.fetchUserHandler}
                    close={userContext.closeEligibilityModal}
                  />
                )}
              </React.Fragment>
            )
          }
        </UserProfileContext.Consumer>
      </Page>
    );
  }
}

export default CompleteAccount;
