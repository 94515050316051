import React from 'react';

const ArrowButton = props => (
  <svg
    viewBox="0 0 27 27"
    width="26px"
    height="26px"
    transform={props.up ? 'rotate(180)' : ''}
    {...props}>
    <g transform="translate(.5 .5)">
      <circle
        data-name="Oval Copy 2"
        cx={13}
        cy={13}
        r={13}
        fill="#fff"
        stroke="#979797"
        strokeMiterlimit={10}
      />
      <path data-name="Shape Copy" d="M8 11l5 5 5-5z" fill="#0da45d" />
    </g>
  </svg>
);

export default ArrowButton;
