import React from 'react';
import { Link } from 'react-router-dom';
import WaitlistCards from './WaitlistCards';

const EligibleWaitlistCards = ({
  formattedResults,
  handleAlert,
  joinedWaitlists,
  fetchUserHandler,
  applicantId,
}) => {
  if (formattedResults.length === 0) {
    return null;
  }

  const joinedWaitlistIds = joinedWaitlists.map(waitlist =>
    waitlist.attributes.waitlist_id.toString()
  );

  const filteredWaitlists = formattedResults.reduce((acc, waitlistData) => {
    if (!joinedWaitlistIds.includes(waitlistData.waitlist.id)) {
      acc.push(waitlistData);
    }
    return acc;
  }, []);

  if (filteredWaitlists.length === 0) {
    return null;
  }

  return (
    <div className="my-5">
      <h2>Additional waitlists you can join:</h2>
      <WaitlistCards
        formattedResults={filteredWaitlists.slice(0, 4)}
        applicantId={applicantId}
        handleAlert={handleAlert}
        joinedWaitlists={joinedWaitlists}
        showPropertySummaries={false}
        fetchUserHandler={fetchUserHandler}
      />
      <div className="d-flex justify-content-end">
        <Link to="/browse" className="btn btn-blue ml-1">
          Find More Waitlists
        </Link>
      </div>
    </div>
  );
};
export default EligibleWaitlistCards;
