import { Component } from 'react';
import { handleJSONResponse, apiURL } from 'utils/api';
import { groupWaitlistResultsDataByType } from 'utils/waitlistSearch';

class WaitlistSearchHandler extends Component {
  state = {
    formattedResults: [],
    loading: false,
  };

  componentDidMount() {
    this.handleFetchWaitlists();
  }

  extractAssociatedData(relationshipIds, associatedData) {
    return relationshipIds.reduce((acc, item) => {
      const id = item.id;
      const itemData = associatedData.find(associatedItem => {
        return associatedItem.id === id;
      });

      acc.push(itemData);

      return acc;
    }, []);
  }

  formatWaitlistResults(data) {
    return data.waitlists.map(w => {
      let waitlistData = {
        waitlist: w,
      };
      waitlistData[
        'associatedProperties'
      ] = w.relationships.properties.data.reduce((acc, associatedProperty) => {
        const propertyId = associatedProperty.id;
        const propertyData = data.properties.find(property => {
          return property.id === propertyId;
        });
        acc.push(propertyData);

        return acc;
      }, []);

      waitlistData['associatedProperties'].forEach(property => {
        property['associatedPropertyImages'] = this.extractAssociatedData(
          property.relationships.property_images.data,
          data.propertyImages
        );

        property['associatedLocations'] = this.extractAssociatedData(
          property.relationships.locations.data,
          data.locations
        );

        property['featuredPropertyImage'] = property[
          'associatedPropertyImages'
        ].filter(propertyImage => {
          return propertyImage.attributes.is_featured_image === true;
        });
      });

      return waitlistData;
    });
  }

  handleFetchWaitlists = () => {
    this.setState({ loading: true });
    const { searchParams, applicantId } = this.props;
    let params = '';
    Object.keys(searchParams).forEach(param => {
      if (typeof searchParams[param] === "undefined") {
        return;
      }

      if (param === 'locations' || param === 'preferred_bedroom_sizes') {
        params += `${param}=${JSON.stringify(searchParams[param])}&`;
      } else {
        params += `${param}=${searchParams[param]}&`;
      }
    });

    if (applicantId && applicantId !== null) {
      params += `applicant_id=${applicantId.toString()}`;
    }

    fetch(apiURL(`/waitlists?${params}`), {
      method: 'GET',
    })
      .then(resp => handleJSONResponse(resp))
      .then(resp => {
        const dataToFormat = groupWaitlistResultsDataByType(resp, 'waitlists');
        const formattedResults = this.formatWaitlistResults(dataToFormat);
        this.setState(
          {
            formattedResults,
          },
          () => {
            if (!this.props.numberOfResultsUpdater) {
              return;
            }
            this.props.numberOfResultsUpdater(
              this.state.formattedResults.length
            );
          }
        );
      })
      .catch(err => {
        let msg = ['Sorry, something went wrong.'];
        if (err.errors) {
          msg = err.errors.map(e => e).join(', ');
        }

        this.props.handleAlert({
          show: true,
          extraClasses: ['text-white', 'text-center'],
          dismissable: true,
          removeAlert: () => this.props.handleAlert({}),
          msg,
          type: 'danger',
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    return this.props.render(this.state);
  }
}

export default WaitlistSearchHandler;
