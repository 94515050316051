import React from 'react';
import moment from 'moment';
import Fields from 'components/Form/Fields';
import FormCard from 'components/Form/FormCard';
import { states } from 'utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Popover from 'components/Popover';

const names = [
  {
    name: 'full_name',
    label: 'Full Name',
    input: 'input',
    type: 'text',
    containerClasses: ['form-group', 'col'],
    classes: ['form-control'],
    describedBy: 'full_name',
  },
  {
    name: 'preferred_name',
    label: 'Preferred Name',
    input: 'input',
    type: 'text',
    containerClasses: ['form-group', 'col'],
    classes: ['form-control'],
    describedBy: 'preferred_name',
  },
];

const dobField = [
  {
    name: 'date_of_birth',
    label: 'Date of Birth',
    input: 'input',
    type: 'date',
    max: moment().subtract(16, 'years').format('YYYY-MM-DD'),
    containerClasses: ['form-group', 'col'],
    classes: ['form-control'],
    describedBy: 'date_of_birth',
  },
];

const mailingAddressFields = [
  {
    name: 'address_one',
    label: 'Address',
    input: 'input',
    type: 'text',
    containerClasses: ['form-group', 'col'],
    classes: ['form-control'],
    describedBy: 'address_one',
  },
  {
    name: 'address_two',
    label: 'Address 2',
    input: 'input',
    type: 'text',
    containerClasses: ['form-group', 'col'],
    classes: ['form-control'],
    describedBy: 'address_two',
  },
  {
    name: 'city',
    label: 'City Name',
    input: 'input',
    type: 'text',
    containerClasses: ['form-group', 'col'],
    classes: ['form-control'],
    describedBy: 'city',
  },
  [
    {
      name: 'state',
      label: 'State',
      input: 'select',
      type: 'select',
      options: states,
      containerClasses: ['form-group', 'col-8'],
      classes: ['react-select'],
      describedBy: 'state',
    },
    {
      name: 'zip_code',
      label: 'Zip Code',
      input: 'input',
      type: 'text',
      max: '10',
      containerClasses: ['form-group', 'col-4'],
      classes: ['form-control'],
      describedBy: 'zip_code',
    },
  ],
  {
    ...dobField[0],
  },
  {
    name: 'receives_mail',
    label: 'Can you receive mail at this address?',
    input: 'input',
    type: 'radio',
    options: [
      {
        value: 'yes',
        label: 'Yes',
      },
      {
        value: 'no',
        label: 'No',
      },
    ],
    containerClasses: [
      'form-group',
      'col',
      'form-check-inline',
      'px-3',
      'mr-0',
    ],
    classes: ['custom-control', 'custom-radio', 'ml-2'],
    describedBy: 'receives_mail',
  },
];

const phoneFields = [
  {
    name: 'phone_number',
    label: 'Phone Number',
    input: 'input',
    type: 'tel',
    max: '12',
    containerClasses: ['form-group', 'col'],
    classes: ['form-control'],
    describedBy: 'phone_number',
  },
  {
    name: 'confirm_phone_number',
    label: 'Confirm Phone Number',
    input: 'input',
    type: 'tel',
    max: '12',
    containerClasses: ['form-group', 'col'],
    classes: ['form-control'],
    describedBy: 'confirm_phone_number',
  },
  {
    name: 'receives_text',
    label: 'Receive text messages from us',
    input: 'input',
    type: 'radio',
    options: [
      { value: 'yes', label: 'Yes' },
      { value: 'no', label: 'No' },
    ],
    containerClasses: ['form-group', 'col', 'form-check-inline', 'px-3'],
    classes: ['custom-control', 'custom-radio', 'ml-2'],
    describedBy: 'receives_text',
  },
];

const includeAlt = [
  {
    name: 'include_alt_contact',
    label: 'Add an alternate to my account',
    input: 'input',
    type: 'radio',
    options: [
      { value: 'yes', label: 'Yes' },
      { value: 'no', label: 'No' },
    ],
    containerClasses: [
      'form-group',
      'col',
      'form-check-inline',
      'px-3',
      'mr-0',
    ],
    classes: ['custom-control', 'custom-radio', 'ml-2'],
    describedBy: 'include_alt_contact',
  },
];

const altEmail = [
  {
    name: 'alt_contact_email',
    label: 'Email Address',
    input: 'input',
    type: 'email',
    containerClasses: ['form-group', 'col', 'pb-3'],
    classes: ['form-control'],
    describedBy: 'alt_contact_email',
  },
];

const altContactMethod = [
  {
    name: 'alt_contact_method',
    label: 'Contact Method',
    input: 'select',
    type: 'select',
    options: [
      {
        label: 'Mail',
        value: 'mail',
      },
      {
        label: 'Email',
        value: 'email',
      },
      {
        label: 'Phone',
        value: 'phone',
      },
    ],
    containerClasses: ['form-group', 'col'],
    classes: ['react-select'],
    describedBy: 'alt_contact_method',
  },
];

const altContent = field => {
  const newObj = { ...field };
  newObj.name = `alt_${field.name}`;
  return newObj;
};

const altFields = array =>
  array.map(field =>
    Array.isArray(field)
      ? field.map(nestedField => altContent(nestedField))
      : altContent(field)
  );

const ExtendedIntake = props => {
  return (
    <React.Fragment>
      <FormCard>
        <h2 className="text-center">Your Contact Information</h2>
        <div className="row">
          <div className="col px-0 px-sm-3">
            <div className="two-column mt-3">
              <Fields inputs={mailingAddressFields} {...props} />
              <Fields inputs={phoneFields} {...props} />
              <hr />
              <Fields inputs={includeAlt} {...props} />
            </div>
          </div>
        </div>
      </FormCard>
      {props.formValues.include_alt_contact === 'yes' && (
        <FormCard>
          <div className="row mb-2">
            <div className="col d-flex align-items-center justify-content-center">
              <h2 className="mb-0">Alternate Contact Information</h2>
              {props.popovers &&
                Object.keys(props.popovers).includes('deleteAltContact') && (
                  <Popover
                    trigger={
                      <button
                        className="btn-icon fa-layers fa-fw mx-3"
                        aria-label="remove alternate contact"
                        onClick={e =>
                          props.togglePopover(e, 'deleteAltContact')
                        }
                      >
                        <FontAwesomeIcon
                          icon="circle"
                          color="#d0021b"
                          size="2x"
                        />
                        <FontAwesomeIcon
                          icon="trash"
                          color="white"
                          transform="right-7"
                        />
                        <span className="sr-only">Delete Alt Contact</span>
                      </button>
                    }
                    show={props.popovers.deleteAltContact}
                    header="Delete contact?"
                  >
                    <React.Fragment>
                      <p>Are you sure? This cannot be undone.</p>
                      <div className="d-flex flex-nowrap">
                        <button
                          className="btn btn-danger mx-2"
                          onClick={e => props.delete(e, 'deleteAltContact')}
                        >
                          Delete
                        </button>
                        <button
                          className="btn btn-outline-secondary mx-2"
                          onClick={e =>
                            props.togglePopover(e, 'deleteAltContact')
                          }
                        >
                          Cancel
                        </button>
                      </div>
                    </React.Fragment>
                  </Popover>
                )}
            </div>
          </div>

          <div className="row">
            <div className="col px-0 px-sm-3">
              <div className="two-column mt-3">
                <Fields inputs={altFields(names)} {...props} />
                <Fields inputs={altContactMethod} {...props} />
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col px-0 px-sm-3">
              <div className="two-column">
                {props.formValues.alt_contact_method.value === 'mail' && (
                  <Fields inputs={altFields(mailingAddressFields)} {...props} />
                )}
                {props.formValues.alt_contact_method.value === 'email' && (
                  <Fields inputs={altEmail} {...props} />
                )}
                {props.formValues.alt_contact_method.value === 'phone' && (
                  <Fields inputs={altFields(phoneFields)} {...props} />
                )}
              </div>
            </div>
          </div>
        </FormCard>
      )}
    </React.Fragment>
  );
};

export default ExtendedIntake;
