import React from 'react';
import { propertyAttributes } from 'utils/constants';
import PropertyBuildingInfo from './PropertyBuildingInfo';

// WARNING changes to this file must also be made in housing_hub/app/javascript/components/Properties/FullPropertyDetails
const FullPropertyDetails = props => {
  const {
    property: { attributes, relationships },
    locations,
  } = props;

  return (
    <div className="row mx-0">
      <div className="col-md-5">
        <PropertyBuildingInfo
          attributes={attributes}
          relationships={relationships}
          locations={locations}
        />
      </div>
      <div className="col-md-7">
        {Object.keys(propertyAttributes).map(attribute => {
          return (
            <h4 key={attribute}>
              {propertyAttributes[attribute]}:{' '}
              {Array.isArray(attributes[attribute]) ? (
                <span className="font-weight-normal">
                  {attributes[attribute].length > 0
                    ? attributes[attribute].join(', ')
                    : `No ${attribute.replace(/_/g, ' ')}`}
                </span>
              ) : (
                <span className="font-weight-normal">
                  {attributes[attribute]
                    ? `$${attributes[attribute]}`
                    : `No ${attribute.replace(/_/g, ' ')}`}
                </span>
              )}
            </h4>
          );
        })}
      </div>
    </div>
  );
};

export default FullPropertyDetails;
