export const nestApplicantInfoFields = (state, nestedObj) => {
  const {
    accessible_unit,
    mobility_device,
    disabled,
    domestic_violence,
    displaced,
    working_family,
    homeless,
    senior_citizen,
    household_size,
    household_income,
    info_id,
    ...rest
  } = state;

  return {
    ...rest,
    [nestedObj]: {
      accessible_unit,
      mobility_device,
      disabled,
      domestic_violence,
      displaced,
      working_family,
      homeless,
      senior_citizen,
      household_size,
      household_income,
      id: info_id,
    },
  };
};

export const getAddressFields = fields => {
  const {
    address_one,
    address_two,
    city,
    state,
    zip_code,
    alt_address_one,
    alt_address_two,
    alt_city,
    alt_state,
    alt_zip_code,
  } = fields;

  return {
    primary: {
      address_one,
      address_two,
      city,
      state: state && state.value ? state.value : state,
      zip_code,
    },
    alt: {
      alt_address_one,
      alt_address_two,
      alt_city,
      alt_state: alt_state.value ? alt_state.value : alt_state,
      alt_zip_code,
    },
  };
};

export const initializeHouseholdMembers = (householdSize, householdMembers) => {
  if (!householdMembers) {
    return [];
  }

  const currentHouseholdMembers = householdMembers.filter(
    member => !member._destroy
  );
  const sizeDifference = householdSize - 1 - currentHouseholdMembers.length;

  if (sizeDifference > 0) {
    // add new row of empty fields
    const empty_rows = [];
    for (let i = 0; i < sizeDifference; i++) {
      empty_rows.push({});
    }
    return householdMembers.concat(empty_rows);
  } else if (sizeDifference < 0) {
    // check if there are any rows that have not been saved to the database
    const unsavedHouseholdMembers = householdMembers.filter(
      member => !member.id
    );

    if (unsavedHouseholdMembers.length > 0) {
      // return sliced array – since there are unsaved rows at the end, there is no need to add _destroy flag
      return householdMembers.slice(0, householdMembers.length - 1);
    }

    // if there are no unsaved rows, all rows have been saved to the database
    // therefore, we need to add _destroy flags if the user is removing household members
    for (let i = householdMembers.length - 1; i >= 0; i--) {
      if (householdMembers[i]._destroy !== true) {
        householdMembers[i] = { ...householdMembers[i], _destroy: true };
        break;
      }
    }
    return householdMembers;
  }
  // if there is no size difference, just return the original array
  return householdMembers;
};

export const convertKeysToAlt = addr =>
  Object.keys(addr).reduce(
    (acc, key) =>
      key.startsWith('alt_')
        ? { ...acc, [key]: addr[key] }
        : {
            ...acc,
            [`alt_${key}`]: addr[key],
          },
    {}
  );
