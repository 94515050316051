import React from 'react';
import Fields from 'components/Form/Fields';

const configuration = [
  {
    name: 'domestic_violence',
    label: 'Are you a victim of domestic violence?',
    labelClasses: ['flex-shrink-0'],
    input: 'input',
    type: 'radio',
    options: [
      {
        value: 'yes',
        label: 'Yes'
      },
      {
        value: 'no',
        label: 'No'
      }
    ],
    containerClasses: [
      'form-group',
      'col',
      'form-check-inline',
      'mr-0',
      'flex-wrap',
      'text-md-left',
      'text-center',
      'justify-content-center',
      'justify-content-md-start'
    ],
    classes: ['custom-control', 'custom-radio', 'mr-3'],
    describedBy: 'domestic_violence'
  },
  {
    name: 'accessible_unit',
    label: 'Does anyone in your household require an accessible unit?',
    labelClasses: ['flex-shrink-0'],
    input: 'input',
    type: 'radio',
    options: [{ value: 'yes', label: 'Yes' }, { value: 'no', label: 'No' }],
    containerClasses: [
      'form-group',
      'col',
      'form-check-inline',
      'mr-0',
      'flex-wrap',
      'text-md-left',
      'text-center',
      'justify-content-center',
      'justify-content-md-start'
    ],
    classes: ['custom-control', 'custom-radio', 'mr-3'],
    describedBy: 'accessible_unit'
  },
  {
    name: 'displaced',
    label: 'Are you currently displaced?',
    labelClasses: ['flex-shrink-0'],
    input: 'input',
    type: 'radio',
    options: [{ value: 'yes', label: 'Yes' }, { value: 'no', label: 'No' }],
    containerClasses: [
      'form-group',
      'col',
      'form-check-inline',
      'mr-0',
      'flex-wrap',
      'text-md-left',
      'text-center',
      'justify-content-center',
      'justify-content-md-start'
    ],
    classes: ['custom-control', 'custom-radio', 'mr-3'],
    describedBy: 'displaced'
  },
  {
    name: 'mobility_device',
    input: 'input',
    type: 'checkbox',
    options: [
      {
        value: true,
        label:
          'I have a mobility impairment that requires the features of an accessible unit'
      }
    ],
    containerClasses: ['form-group', 'col', 'text-md-left', 'text-center'],
    classes: ['custom-control', 'custom-checkbox'],
    describedBy: 'mobility_device'
  },
  {
    name: 'working_family',
    label: 'Are you living in a working family?',
    labelClasses: ['flex-shrink-0'],
    input: 'input',
    type: 'radio',
    options: [{ value: 'yes', label: 'Yes' }, { value: 'no', label: 'No' }],
    containerClasses: [
      'form-group',
      'col',
      'form-check-inline',
      'mr-0',
      'flex-wrap',
      'text-md-left',
      'text-center',
      'justify-content-center',
      'justify-content-md-start'
    ],
    classes: ['custom-control', 'custom-radio', 'mr-3'],
    describedBy: 'working_family'
  }
];

const ExtendedIntake = props => {
  return (
    <div className="two-column">
      <Fields inputs={configuration} {...props} />
    </div>
  );
};

export default ExtendedIntake;
