import USPS from 'usps-webtools-promise';
import pickBy from 'lodash.pickby';
import { convertKeysToAlt } from './fields';

const usps = new USPS({
  server: 'https://secure.shippingapis.com/ShippingAPI.dll',
  properCase: true,
  userId: process.env.REACT_APP_USPS_ID,
  ttl: 10000, //TTL in milliseconds for request
});

const convertAddressKeys = (addr, type) => {
  if (type === 'usps') {
    return isPrimaryAddress(addr)
      ? {
          Address1: addr.address_one,
          Address2: addr.address_two,
          City: addr.city,
          State: addr.state,
          Zip5: addr.zip_code,
        }
      : {
          Address1: addr.alt_address_one,
          Address2: addr.alt_address_two,
          City: addr.alt_city,
          State: addr.alt_state,
          Zip5: addr.alt_zip_code,
        };
  } else
    return {
      address_one: addr.Address1,
      address_two: typeof addr.Address2 === 'undefined' ? '' : addr.Address2,
      city: addr.City,
      state: addr.State,
      zip_code: addr.Zip5,
    };
};

const isPrimaryAddress = addr =>
  addr.address_one ||
  addr.address_two ||
  addr.city ||
  addr.state ||
  addr.zip_code;

const validateAddress = userAddress =>
  new Promise((resolve, reject) => {
    usps.verify(convertAddressKeys(userAddress, 'usps')).then(
      officialAddress => {
        resolve(convertAddressKeys(officialAddress));
      },
      error => {
        let message =
          'We are having trouble connecting to USPS. Some addresses could not be verified.';
        let source = 'server';
        if (!error.during) {
          message = error.message || message;
          source = 'user';
        }
        error = {
          message,
          source,
        };
        reject({ error, ...userAddress });
      }
    );
  });

export const uspsAddress = addr =>
  validateAddress(addr).catch(({ error, ...address }) => ({
    error,
    ...address,
  }));

export const normalizeAddresses = ({
  addresses,
  include_alt_contact,
  alt_contact_method,
}) => {
  const userAddress = uspsAddress(addresses.primary);
  let altAddress = Promise.resolve();

  // Confirm user is including mail alt contact and address has values
  if (
    (include_alt_contact || include_alt_contact === 'yes') &&
    alt_contact_method === 'mail' &&
    Object.keys(pickBy(addresses.alt, val => val !== '')).length > 0
  ) {
    altAddress = uspsAddress(addresses.alt).then(convertKeysToAlt);
  }

  return Promise.all([userAddress, altAddress]).then(addresses => addresses);
};
