import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AccountContext } from 'screens/CreateAccount/Data/Provider';

class Permission extends Component {
  render() {
    const Component = this.props.component;

    return (
      <div>
        <AccountContext.Consumer>
          {accountContext =>
            accountContext.browsingEnabled ? (
              <Route render={() => <Component {...this.props} />} />
            ) : (
              <Redirect
                to={{
                  pathname: '/intake',
                  state: { from: this.props.location }
                }}
              />
            )
          }
        </AccountContext.Consumer>
      </div>
    );
  }
}

export default Permission;
