import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import queryString from 'query-string';

import ResetPasswordForm from './Form/ResetPassword';
import SetNewPasswordForm from './Form/SetNewPassword';
import { AuthContext } from 'screens/Login/Data/Provider';

class ResetPassword extends React.Component {
  state = {
    token: null
  };

  componentDidMount() {
    const { history, location } = this.props;
    const query = queryString.parse(location.search);

    if (query['access-token'] && query.client && query.uid) {
      const { client, uid } = query;
      const token = {
        'access-token': query['access-token'],
        client,
        uid
      };

      history.replace({
        ...location,
        search: ''
      });
      this.setState({
        token
      });
    }
  }

  handleSendResetInstructions = (authContext, values) =>
    authContext.sendPasswordReset(values)
      .then(() => this.props.history.push('/login'));

  handleSetNewPassword = (authContext, values) => {
    const { token } = this.state;

    authContext.setNewPassword(values, token).then(
      this.setState({
        token: null
      })
    );
  };

  render() {
    const { token } = this.state;

    return (
      <AuthContext.Consumer>
        {authContext => {
          if (authContext.loggedIn) {
            return <Redirect to="/hub" />;
          }

          if (token) {
            return (
              <SetNewPasswordForm
                handleSubmit={formValues =>
                  this.handleSetNewPassword(authContext, formValues)
                }
              />
            );
          }

          return (
            <ResetPasswordForm
              handleSubmit={formValues =>
                this.handleSendResetInstructions(authContext, formValues)
              }
            />
          );
        }}
      </AuthContext.Consumer>
    );
  }
}

export default withRouter(ResetPassword);
