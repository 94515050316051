import React from 'react';
import { mappedPreferences } from 'utils/constants';
import { AccountContext } from 'screens/CreateAccount/Data/Provider';

// WARNING changes to this file must also be made in housing_hub/app/javascript/components/Waitlists/WaitlistPageMeta
const WaitlistPageMeta = ({
  applicantPreferences,
  applicantHouseholdSize,
  attributes,
  applicantId = null,
}) => {
  const matchedPreferences = () => {
    const matches = attributes.preferences.reduce((acc, pref) => {
      if (applicantPreferences[mappedPreferences[pref]] === true) {
        acc.push(pref);
      }
      return acc;
    }, []);

    if (matches.length === 0) {
      return 'No Preferences';
    }
    return matches.join(', ');
  };

  const applicantIncomeLimit = attributes.household_income_limits.find(
    incomeLimit => {
      return incomeLimit.household_size === applicantHouseholdSize;
    }
  );

  return (
    <div>
      <div className="my-5">
        <h4>
          Waitlist Type:{' '}
          <span className="font-weight-normal">
            {attributes.waitlist_types.join(', ')}
          </span>
        </h4>
        <h4>
          Bedrooms:{' '}
          <span className="font-weight-normal">
            {attributes.unit_groups.join(', ')}
          </span>
        </h4>
        <h4>
          Applicant Requirements:{' '}
          <span className="font-weight-normal">
            {attributes.applicant_requirements.join(', ')}
          </span>
        </h4>
        <AccountContext.Consumer>
          {
            accountContext =>
              accountContext.browsingEnabled || applicantId !== null ? (
                <>
                  <h4>
                    Preference Characteristics I Match:{' '}
                    <span className="font-weight-normal">
                      {matchedPreferences()}
                    </span>
                  </h4>
                  <h4>
                    Yearly Minimum Income for My Household Size:{' '}
                    <span className="font-weight-normal">
                      {applicantIncomeLimit &&
                        `$${applicantIncomeLimit.income_min.toLocaleString()}`}
                    </span>
                  </h4>
                  <h4>
                    Yearly Maximum Income for My Household Size:{' '}
                    <span className="font-weight-normal">
                      {applicantIncomeLimit &&
                        `$${applicantIncomeLimit.income_max.toLocaleString()}`}
                    </span>
                  </h4>
                </>
              ) : null // TODO: put tabular data here if straight to link
          }
        </AccountContext.Consumer>
      </div>
      <div className="my-5">
        <h2>Waitlist Description</h2>
        <p>{attributes.description}</p>
      </div>
    </div>
  );
};

export default WaitlistPageMeta;
