import React from 'react';

import UserNamePasswordForm from 'screens/CreateAccount/Form/UserNamePassword';
import ContactInfoForm from 'screens/CreateAccount/Form/ContactInfo';
import PreferencesForm from 'screens/CreateAccount/Form/Preferences';
import NotificationPreferences from 'screens/Profile/NotificationPreferences';
import IntakeForm from 'screens/Intake/Form/Intake';
import FormCard from 'components/Form/FormCard';
import Form from 'components/Form/Form';

const ProfileFields = ({ profile, canSubmit, ...props }) => {
  return !profile.isSet ? null : (
    <React.Fragment>
      <FormCard>
        <Form
          formToEvaluate={profile.user}
          defaultForm={profile.user}
          updater={profile.updateValue}
          component={UserNamePasswordForm}
          canSubmit={{
            // If <field> value is different from <value>, tests whether
            // <field> value matches "Confirm <field>" value.
            //
            // See FormHandler.js for details.
            test: [
              { field: 'email', value: profile.originalEmail },
              { field: 'password', value: '' },
            ],
            callback: bool => canSubmit(bool),
          }}
        />
      </FormCard>
      <Form
        formToEvaluate={profile.user}
        defaultForm={profile.user}
        component={ContactInfoForm}
        updater={profile.updateValue}
        {...props}
      />
      <FormCard>
        <Form
          formToEvaluate={profile.user}
          defaultForm={profile.user}
          updater={profile.updateValue}
          component={IntakeForm}
        />
      </FormCard>
      <FormCard>
        <Form
          formToEvaluate={profile.user}
          defaultForm={profile.user}
          component={NotificationPreferences}
          updater={profile.updateValue}
          {...props}
        />
      </FormCard>
      <FormCard>
        <Form
          formToEvaluate={profile.user}
          defaultForm={profile.user}
          component={PreferencesForm}
          updater={profile.updateValue}
        />
      </FormCard>
    </React.Fragment>
  );
};
export default ProfileFields;
