import React from 'react';
import {
  requiredCreateAccountFields,
  requiredIntakeFields,
} from './Data/Provider';
import UserNamePasswordForm from './Form/UserNamePassword';
import ContactInfoForm from './Form/ContactInfo';
import PreferencesForm from './Form/Preferences';
import IntakeForm from 'screens/Intake/Form/Intake';
import FormCard from 'components/Form/FormCard';
import Form from 'components/Form/Form';

class CreateAccount extends React.Component {
  render() {
    const { account } = this.props;

    if (!this.props.account) {
      return null;
    }

    return (
      <React.Fragment>
        <div className="row mb-3">
          <div className="col-md-8 m-auto text-center">
            <h1 className="green-text mt-5 pt-5">Create Your Account</h1>
            <h4>
              Before you can join a waitlist you will need to create an account
            </h4>
          </div>
        </div>
        <FormCard colWidth="col-lg-9 mx-auto">
          <Form
            formToEvaluate={account}
            defaultForm={requiredCreateAccountFields}
            updater={account.updateValue}
            component={UserNamePasswordForm}
            {...this.props}
          />
        </FormCard>
        <div className="row">
          <div className="col-lg-9 mx-auto">
            <Form
              formToEvaluate={account}
              defaultForm={requiredCreateAccountFields}
              component={ContactInfoForm}
              updater={account.updateValue}
              {...this.props}
            />
          </div>
        </div>
        <FormCard colWidth="col-lg-9 mx-auto">
          <Form
            formToEvaluate={account}
            component={PreferencesForm}
            updater={account.updateValue}
            {...this.props}
          />
        </FormCard>
        <FormCard colWidth="col-lg-9 mx-auto">
          <Form
            formToEvaluate={account}
            defaultForm={requiredIntakeFields}
            updater={account.updateValue}
            component={IntakeForm}
            {...this.props}
          />
        </FormCard>
      </React.Fragment>
    );
  }
}

export default CreateAccount;
