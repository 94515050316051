import React from 'react';

const NotificationCard = ({
  notification: {
    id,
    attributes: { title, created_at, body }
  },
  handleChangeReadStatus,
  buttonText,
  ...props
}) => {
  return (
    <div className="card p-4 mb-3 card-shadow">
      <div className="row">
        <div className="col-md-8">
          <h5>{title}</h5>
          <p className="mb-0">{created_at}</p>
        </div>
        <div className="col-md-4">
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-blue"
              onClick={() => handleChangeReadStatus({ id: id, ...props })}>
              {buttonText}
            </button>
          </div>
        </div>
      </div>
      <hr />
      <p>{body}</p>
    </div>
  );
};

export default NotificationCard;
