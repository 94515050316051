import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from 'screens/Login/Data/Provider';
import UserProfileProvider, {
  UserProfileContext,
} from 'screens/Profile/Data/Provider';
import PermissionToBrowse from 'screens/Browse/Data/Permission';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <AuthContext.Consumer>
        {authContext =>
          authContext.loaded ? (
            authContext.loggedIn ? (
              <UserProfileProvider user={authContext.user}>
                <UserProfileContext.Consumer>
                  {userContext => {
                    if (!userContext.isSet) {
                      return null;
                    }

                    if (
                      (userContext.user.has_fake_email ||
                        userContext.user.has_temp_password) &&
                      props.location.pathname !== '/claim-account'
                    ) {
                      return <Redirect to="/claim-account" />;
                    } else if (
                      userContext.user.incomplete_account &&
                      props.location.pathname !== '/complete-account' &&
                      props.location.pathname !== '/claim-account'
                    ) {
                      return <Redirect to="/complete-account" />;
                    }

                    return <Component {...props} />;
                  }}
                </UserProfileContext.Consumer>
              </UserProfileProvider>
            ) : (
              <PermissionToBrowse {...props} component={Component} />
            )
          ) : null
        }
      </AuthContext.Consumer>
    )}
  />
);

export default PrivateRoute;
