import React from 'react';
import PropTypes from 'prop-types';

class GlobalAlert extends React.Component {
  componentDidMount = () => {
    if (this.props.timeout && typeof this.props.timeout === 'number') {
      setTimeout(() => {
        this.handleDestroyAlert();
      }, this.props.timeout);
    }
  };

  componentWillUnmount = () => {
    if (this.props.destroyOnRouteChange) {
      this.handleDestroyAlert();
    }
    clearTimeout();
  };

  handleDestroyAlert = () => {
    this.props.removeAlert();
  };

  render() {
    const { ...alert } = this.props;

    return (
      alert.show && (
        <div
          className={`alert alert-${alert.type} ${alert.extraClasses.join(
            ' '
          )}`}>
          {alert.msg}
          {alert.dismissable && (
            <button
              className="close"
              onClick={alert.removeAlert}
              aria-label="dismiss alert">
              <span>&times;</span>
            </button>
          )}
        </div>
      )
    );
  }
}

GlobalAlert.propTypes = {
  dismissable: PropTypes.bool,
  msg: PropTypes.string.isRequired,
  extraClasses: PropTypes.array,
  type: PropTypes.string.isRequired,
  show: PropTypes.bool,
  removeAlert: PropTypes.func,
  destroyOnRouteChange: PropTypes.bool,
  timeout: PropTypes.number
};

export default GlobalAlert;
