import React, { Component } from 'react';
import moment from 'moment';

class HouseholdMemberInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id,
      full_name: props.full_name,
      relationship: props.relationship,
      date_of_birth: props.date_of_birth,
      memberIndex: props.memberIndex
    };
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState(
      {
        ...this.state,
        [name]: value
      },
      () => {
        this.props.handleMemberUpdate(this.state);
      }
    );
  }

  render() {
    const {
      id,
      full_name,
      relationship,
      date_of_birth,
      memberIndex
    } = this.state;

    return (
      <div className="row mb-2" key={`household-member-${memberIndex}`}>
        <div className="form-group col">
          <div className="row">
            <div className="col-md-4">
              {id && (
                <input
                  type="hidden"
                  name="household-member-id"
                  id={`household-member-id_${memberIndex}`}
                  className="form-control"
                  aria-describedby="household-member-id"
                  value={id}
                />
              )}
              <input
                type="text"
                name="full_name"
                id={`full_name_${memberIndex}`}
                className="form-control"
                aria-describedby="full-name"
                value={full_name}
                onChange={e => this.handleInputChange(e, memberIndex)}
              />
            </div>
            <div className="col-md-4">
              <input
                type="text"
                name="relationship"
                id={`relationship_${memberIndex}`}
                className="form-control"
                aria-describedby="full-name"
                value={relationship}
                onChange={e => this.handleInputChange(e, memberIndex)}
              />
            </div>
            <div className="col-md-3">
              <input
                name="date_of_birth"
                id={`date_of_birth_${memberIndex}`}
                type="date"
                className="form-control"
                aria-describedby="date-of-birth"
                value={date_of_birth}
                max={moment().format('YYYY-MM-DD')}
                onChange={e => this.handleInputChange(e, memberIndex)}
              />
            </div>
            <div className="col-md-1 px-0 d-flex align-items-center">
              {id && (
                <button
                  className="red-link font-weight-bold"
                  onClick={() => this.props.handleMemberDelete(this.state)}>
                  Clear
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HouseholdMemberInput;
