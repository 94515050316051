import React from 'react';
import { Link } from 'react-router-dom';
import EmptyState from 'components/EmptyState';
import EligibleWaitlistCards from './EligibleWaitlistCards';
import JoinedWaitlistCards from './JoinedWaitlistCards';
import Loader from 'react-loader-spinner';

const HubWaitlists = props => {
  if (props.loading === true) {
    return (
      <EmptyState>
        <h3>
          <Loader
            className="loader-container"
            type="Oval"
            color="#00BFFF"
            height={22}
            width={22}
          />
          <span>Loading Waitlists...</span>
        </h3>
      </EmptyState>
    );
  }

  if (props.formattedResults.length === 0) {
    return (
      <EmptyState>
        <h3>
          We were unable to match you to any waitlists. Head over to{' '}
          <Link to="/profile">your profile</Link> to confirm that your
          information is up-to-date.
        </h3>
      </EmptyState>
    );
  }

  return (
    <React.Fragment>
      <JoinedWaitlistCards {...props} />
      <EligibleWaitlistCards {...props} />
    </React.Fragment>
  );
};
export default HubWaitlists;
