import React from 'react';
import { Redirect } from 'react-router-dom';

import { UserProfileContext } from 'screens/Profile/Data/Provider';
import { validate } from 'utils/forms';
import Page from 'components/Page';
import ClaimAccountFields from './Fields';
import ConfirmationModal from './ConfirmationModal';

const requiredClaimAccountFields = {
  full_name: '',
  preferred_name: '',
  email: '',
  confirm_email: '',
  password: '',
  confirm_password: '',
};

class ClaimAccount extends React.Component {
  state = {
    verifyFieldsModal: false,
  };

  openModal = () => {
    this.setState({
      verifyFieldsModal: true,
    });
  };

  closeModal = () => {
    this.setState({
      verifyFieldsModal: false,
    });
  };

  render() {
    return (
      <Page>
        <UserProfileContext.Consumer>
          {userContext =>
            !(
              userContext.user.has_fake_email ||
              userContext.user.has_temp_password
            ) ? (
              <Redirect to="/hub" />
            ) : (
              <>
                <div className="row mb-3">
                  <div className="col-md-8 m-auto text-center">
                    <h1 className="green-text mt-5 pt-5">Claim Account</h1>
                    <h4>Confirm your information to claim this account.</h4>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-9 mx-auto">
                    <ClaimAccountFields profile={userContext} />
                    <div className="row mt-3 mb-5">
                      <div className="col d-flex flex-column align-items-center text-center mt-2">
                        <button
                          className="btn btn-green px-5 my-4"
                          onClick={this.openModal}
                          disabled={
                            !validate(
                              userContext.user,
                              requiredClaimAccountFields
                            )
                          }
                        >
                          Submit
                        </button>
                        <p>All fields are required.</p>
                      </div>
                    </div>
                  </div>
                </div>

                {this.state.verifyFieldsModal && (
                  <ConfirmationModal
                    user={userContext.user}
                    accept={values => {
                      userContext.save({
                        ...values,
                        has_fake_email: false,
                        has_temp_password: false,
                      });
                    }}
                    close={this.closeModal}
                  />
                )}
              </>
            )
          }
        </UserProfileContext.Consumer>
      </Page>
    );
  }
}

export default ClaimAccount;
