import React, { Component } from 'react';
import Modal from 'components/Modal';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { user } from 'actions';

class ConfirmationModal extends Component {
  state = {
    error: null,
    alt_error: null,
    fieldsForSubmit: {},
    isLoaded: false,
  };

  componentDidMount() {
    user
      .fieldsForSubmit({ formState: this.props.user })
      .then(fieldsForSubmit => {
        const { error, alt_error, ...values } = fieldsForSubmit;
        return {
          error,
          alt_error,
          fieldsForSubmit: values,
        };
      })
      .then(fields => {
        this.setState({
          ...fields,
        });
      })
      .then(() => {
        this.setState({
          isLoaded: true,
        });
      });
  }

  closeModal = () => {
    this.props.close();
  };

  handleAccept = () => {
    this.closeModal();
    this.props.accept(this.state.fieldsForSubmit);
  };

  render() {
    const { error, alt_error, isLoaded } = this.state;

    const warning = msg => (
      <div className="alert alert-warning text-center">
        <FontAwesomeIcon icon="exclamation-triangle" /> {msg}
      </div>
    );

    if (!isLoaded) {
      return null;
    }

    return (
      <Modal isOpen>
        {((error && error.source === 'server') ||
          (alt_error && alt_error.source === 'server')) &&
          warning(error.message || alt_error.message)}

        <h2>Accept Changes?</h2>
        <p></p>
        <div className="d-flex w-100 mt-4 justify-content-between">
          <button
            className="btn btn-outline-secondary"
            onClick={this.closeModal}
          >
            Cancel
          </button>
          <button className="btn btn-green" onClick={this.handleAccept}>
            Accept & Save
          </button>
        </div>
      </Modal>
    );
  }
}

ConfirmationModal.propTypes = {
  user: PropTypes.object.isRequired,
  accept: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

export default ConfirmationModal;
