import React, { Component } from 'react';
import { handleJSONResponse, apiURL } from 'utils/api';

export const ApiContext = React.createContext();

class ApiProvider extends Component {
  state = {
    apiReady: false,
    isLoaded: false,
    msg: null
  };

  componentDidMount = () => {
    this.handleApiPing();
  };

  handleApiPing = () => {
    fetch(apiURL('/ping'))
      .then(res => handleJSONResponse(res))
      .then(() => {
        this.setState({
          apiReady: true,
          isLoaded: true
        });
      })
      .catch(err => {
        this.setState({
          isLoaded: true,
          msg: {
            msgHeader: 'Please try again later',
            msg: 'Sorry, it appears the server is down.',
            faIcon: {
              icon: 'error',
              style: 'text-danger'
            },
            cta: {
              link: '/',
              linkText: 'Try again'
            }
          }
        });
      });
  };

  render() {
    return (
      <ApiContext.Provider value={this.state}>
        {this.props.children}
      </ApiContext.Provider>
    );
  }
}

export default ApiProvider;
