import React from 'react';

const HousingLinkLogo = props => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 509.7 94.55"
    {...props}>
    <title>logotype-housing-link</title>
    <polygon
      points="509.7 47.03 496.04 60.69 482.26 46.91 495.92 33.25 509.7 33.25 509.7 47.03"
      fill={props.color || '#ffffff'}
    />
    <polygon
      points="462.19 0 475.85 13.66 462.07 27.44 448.41 13.78 448.41 0 462.19 0"
      fill={props.color || '#ffffff'}
    />
    <polygon
      points="415.15 47.52 428.81 33.86 442.59 47.64 428.93 61.3 415.15 61.3 415.15 47.52"
      fill={props.color || '#ffffff'}
    />
    <polygon
      points="462.67 94.55 449.01 80.89 462.79 67.11 476.45 80.77 476.45 94.55 462.67 94.55"
      fill={props.color || '#ffffff'}
    />
    <polygon
      points="429.17 80.87 429.17 61.55 448.66 61.55 448.66 80.87 438.92 90.61 429.17 80.87"
      fill={props.color || '#ffffff'}
    />
    <polygon
      points="0 68.62 5.05 68.62 5.05 44.62 30.89 44.62 30.89 68.62 35.95 68.62 35.95 17.71 30.89 17.71 30.89 40.24 5.05 40.24 5.05 17.71 0 17.71 0 68.62"
      fill={props.color || '#ffffff'}
    />
    <path
      d="M97.4,358c0-8.61,4.32-15.54,12.37-15.54s12.38,6.93,12.38,15.54-4.32,15.54-12.38,15.54S97.4,366.63,97.4,358m-5.05,0c0,10.14,5.34,19.48,17.42,19.48S127.2,368.16,127.2,358s-5.35-19.47-17.43-19.47S92.35,347.88,92.35,358"
      transform="translate(-47.03 -308)"
      fill={props.color || '#ffffff'}
    />
    <path
      d="M165,339.42h-4.61V360c0,6.2-2.64,13.57-11.06,13.57-6.37,0-8.86-4.52-8.86-11.53V339.42h-4.61V363.2c0,8.17,3.51,14.3,12.74,14.3,7.76,0,10.54-4.16,12.08-7.52h.15v6.64h4.46c-.29-3-.29-5.91-.29-8.75Z"
      transform="translate(-47.03 -308)"
      fill={props.color || '#ffffff'}
    />
    <path
      d="M173.27,375.75a28.25,28.25,0,0,0,10.47,1.75c5.63,0,13-2.7,13-10.58,0-12.18-18.38-11.31-18.38-18.24,0-4.74,3.29-6.19,8.05-6.19a21.77,21.77,0,0,1,8.28,1.75l.44-4a29.52,29.52,0,0,0-9.23-1.67c-6.15,0-12.15,2.77-12.15,10.13,0,10.8,17.94,9.41,17.94,18.24,0,4.23-3.88,6.64-8.28,6.64a22,22,0,0,1-9.74-2.34Z"
      transform="translate(-47.03 -308)"
      fill={props.color || '#ffffff'}
    />
    <path
      d="M209.63,323.37H205v6.13h4.62ZM205,376.62h4.62v-37.2H205Z"
      transform="translate(-47.03 -308)"
      fill={props.color || '#ffffff'}
    />
    <path
      d="M221.24,376.62h4.62V356.05c0-6.2,2.63-13.57,11-13.57,6.37,0,8.86,4.53,8.86,11.53v22.61h4.61V352.84c0-8.17-3.51-14.3-12.74-14.3-7.76,0-10.54,4.17-12.08,7.52h-.15v-6.64H221c.29,3,.29,5.91.29,8.76Z"
      transform="translate(-47.03 -308)"
      fill={props.color || '#ffffff'}
    />
    <path
      d="M291.5,339.42h-4.61v4.23h-.15c-3.44-4.45-6.66-5.1-11.64-5.1-11,0-15.37,8.16-15.37,19.47,0,8.24,3.8,18.6,15.74,18.6,4.76,0,8.86-1.89,11.35-7.29H287c0,10.5-1,19.11-12.89,19.11a25.31,25.31,0,0,1-11-2.63l-.37,4.6a38.92,38.92,0,0,0,11.27,2c12.82,0,17.5-8.46,17.5-18.82ZM264.78,358c0-7.22,2.27-15.54,11.05-15.54,8.42,0,11.06,6.57,11.06,15.54,0,6.78-3,14.66-11.35,14.66-7.83,0-10.76-7.36-10.76-14.66"
      transform="translate(-47.03 -308)"
      fill={props.color || '#ffffff'}
    />
    <polygon
      points="254.4 68.62 285 68.62 285 58.26 268.16 58.26 268.16 17.71 254.4 17.71 254.4 68.62"
      fill={props.color || '#ffffff'}
    />
    <path
      d="M337.68,332.86h13.17V321.92H337.68Zm0,43.76h13.17v-38H337.68Z"
      transform="translate(-47.03 -308)"
      fill={props.color || '#ffffff'}
    />
    <path
      d="M358.76,376.62h13.18V355.76c0-3.94,1.46-7.37,4.75-7.37,2.49,0,3.59,1.75,3.59,5.4v22.83h13.18V350c0-8.39-5-12.26-11.57-12.26-7.47,0-10,5.18-10.76,7.22H371a54.32,54.32,0,0,0-.29-6.34H358.32a70.25,70.25,0,0,1,.44,7.73Z"
      transform="translate(-47.03 -308)"
      fill={props.color || '#ffffff'}
    />
    <polygon
      points="375.78 68.62 390.35 68.62 378.79 48.05 389.47 30.62 376.29 30.62 367.65 45.94 367.51 45.94 367.51 13.91 354.33 13.91 354.33 68.62 367.51 68.62 367.51 50.83 367.65 50.83 375.78 68.62"
      fill={props.color || '#ffffff'}
    />
    <polygon
      points="428.84 14.02 448.16 14.02 448.16 33.51 428.84 33.51 419.1 23.77 428.84 14.02"
      fill={props.color || '#ffffff'}
    />
    <polygon
      points="495.69 13.67 495.69 32.99 476.2 32.99 476.2 13.67 485.94 3.93 495.69 13.67"
      fill={props.color || '#ffffff'}
    />
    <polygon
      points="496.03 80.53 476.71 80.53 476.71 61.04 496.03 61.04 505.77 70.78 496.03 80.53"
      fill={props.color || '#ffffff'}
    />
  </svg>
);

export default HousingLinkLogo;
