import React, { useState } from 'react';
import download from 'downloadjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DocumentItem = ({
  document,
  applicantId,
  handleDelete,
  handleDownload,
  alertHandler,
  fetchUserHandler,
}) => {
  const [loading, setLoading] = useState(false);

  const handleDeleteDocument = id => {
    if (window.confirm('Are you sure you want to delete this document?')) {
      const handleDeleteProps = {
        applicantId,
        documentId: id,
        handleAlert: alertHandler,
        fetchUserHandler,
      };
      handleDelete(handleDeleteProps);
    }
  };

  const handleDownloadDocument = privateFileUrl => {
    if (loading) {
      return;
    }

    setLoading(true);

    const handleDownloadProps = {
      applicantId,
      privateFileUrl,
      handleAlert: alertHandler,
    };

    handleDownload(handleDownloadProps)
      .then(res => {
        if (!res) {
          return;
        }

        const { data, name, content_type } = res.data.attributes;

        download(`data:${content_type};base64,${data}`, name, content_type);

        return res;
      })
      .finally(_res => {
        setLoading(false);
      });
  };

  return (
    <div className="row">
      <div className="col-md-9">
        <a
          href={() => false}
          onClick={() => handleDownloadDocument(document.url)}
          target="_blank"
          rel="noopener noreferrer"
          className="download-link">
          {document.name}
          {loading && <FontAwesomeIcon pulse icon="spinner" size="1x" />}
        </a>
      </div>
      <div className="col-md-3">
        <button
          className="red-link mb-2"
          onClick={() => handleDeleteDocument(document.id)}>
          Delete
        </button>
      </div>
    </div>
  );
};

export default DocumentItem;
