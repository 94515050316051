import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const Input = ({ crop: { input: Input, ...crop }, ...props }) => {
  let input = (
    <Input
      type={crop.type}
      placeholder={crop.placeholder}
      name={crop.name}
      id={crop.name}
      className={`${crop.classes.join(' ')} ${props.error ? 'is-invalid' : ''}`}
      aria-describedby={crop.describedBy}
      onChange={props.change}
      value={props.value}
      disabled={props.disabled ? 'disabled' : ''}
      onBlur={e => props.touched && props.touched(crop.name)}
      min={crop.min && crop.min}
      minLength={crop.min && crop.min}
      max={crop.max && crop.max}
      maxLength={crop.max && crop.max}
    />
  );

  if (crop.type === 'radio' || crop.type === 'checkbox') {
    input = crop.options.map(option => {
      let castBoolean = option.value;

      if (option.value === 'yes') {
        castBoolean = true;
      } else if (option.value === 'no') {
        castBoolean = false;
      }

      return (
        <div
          key={`${crop.name}-${option.value}`}
          className={crop.classes.join(' ')}
        >
          <Input
            type={crop.type}
            placeholder={crop.placeholder}
            name={crop.name}
            id={`${crop.name}-${option.value}`}
            className="custom-control-input"
            aria-describedby={crop.describedBy}
            onChange={props.change}
            checked={
              props.value === option.value || props.value === castBoolean
            }
            value={option.value}
          />
          <label
            id={crop.name}
            className="custom-control-label"
            htmlFor={`${crop.name}-${option.value}`}
          >
            <span>{option.label}</span>
          </label>
        </div>
      );
    });
  }

  if (crop.type === 'select') {
    // React-Select breaks apart event and sends array [object], however
    // we want to maintain event structure for util method
    const handleValue = selection => {
      props.change({
        target: {
          name: crop.name,
          value: selection,
        },
      });
    };

    input = (
      <Select
        className={`${crop.classes.join(' ')} ${
          props.error ? 'is-invalid' : ''
        }`}
        classNamePrefix="react-select"
        value={props.value}
        onChange={val => handleValue(val)}
        onBlur={e => props.touched && props.touched(crop.name)}
        name={crop.name}
        inputId={crop.name}
        isMulti={crop.isMulti}
        size={crop.size}
        options={crop.options}
      />
    );
  }

  return (
    <div className={crop.containerClasses.join(' ')}>
      {crop.label && (
        <label
          className={!crop.labelClasses ? '' : crop.labelClasses.join(' ')}
          htmlFor={crop.name}
        >
          {crop.label}
        </label>
      )}
      {props.beforeInput && props.beforeInput}
      {input}
      {props.afterInput && props.afterInput}
      {props.error && <div className="invalid-feedback">{props.error}</div>}
    </div>
  );
};

Input.propTypes = {
  change: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.array,
    PropTypes.object,
  ]),
  crop: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    input: PropTypes.oneOf(['input', 'select', 'textarea']),
    type: PropTypes.oneOf([
      'text',
      'email',
      'password',
      'checkbox',
      'number',
      'radio',
      'select',
      'textarea',
      'date',
      'tel',
      'hidden',
    ]),
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.bool,
          PropTypes.number,
        ]).isRequired,
        label: PropTypes.string,
      })
    ),
    containerClasses: PropTypes.array,
    classes: PropTypes.array,
    labelClasses: PropTypes.array,
    describedBy: PropTypes.string,
    error: PropTypes.string,
    touched: PropTypes.func,
    min: PropTypes.string,
    max: PropTypes.string,
  }),
};

export default Input;
