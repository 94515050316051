import React from 'react';

const BrowseSidebar = props => {
  return (
    <div className="col-md-3 card p-5">
      {props.handleResetBrowsing && (
        <button
          className="btn btn-blue mt-4"
          onClick={props.handleResetBrowsing}>
          Clear My Search
        </button>
      )}
      <h5>How many people live in your household?</h5>
      <p>{props.searchParams.household_size}</p>
      <h5>What is your household monthly income?</h5>
      <p>${props.searchParams.household_income}</p>
      <h5>Limit search to senior (55+) housing:</h5>
      <p>{props.searchParams.senior_citizen ? 'Yes' : 'No'}</p>
      <h5>Limit search to disabled housing:</h5>
      <p>{props.searchParams.disabled ? 'Yes' : 'No'}</p>
      <h5>Limit search to housing for those experiencing homelessness:</h5>
      <p>{props.searchParams.homeless ? 'Yes' : 'No'}</p>
      <h5>Where do you want to live?</h5>
      {props.searchParams.locations.map(location => {
        return (
          <p key={location.attributes.short_name}>
            {location.attributes.short_name}
          </p>
        );
      })}
    </div>
  );
};

export default BrowseSidebar;
