import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';

import AuthProvider from 'screens/Login/Data/Provider';
import AccountProvider from 'screens/CreateAccount/Data/Provider';
import ApiProvider from 'components/Api/Data/Provider';
import PrivateRoute from 'components/PrivateRoute';
import Intake from 'screens/Intake';
import BrowseWaitlists from 'screens/Browse';
import WaitlistDetails from 'screens/WaitlistDetails';
import Documents from 'screens/Documents';
import CreateAccount from 'screens/CreateAccount';
import Login from 'screens/Login';
import ResetPassword from 'screens/ResetPassword';
import Hub from 'screens/Hub';
import Profile from 'screens/Profile';
import 'utils/fontAwesome';
import Notifications from 'screens/Notifications';
import CompleteAccount from 'screens/CompleteAccount';
import ClaimAccount from 'screens/ClaimAccount';

const AppProviders = ({ children }) => (
  <ApiProvider>
    <AuthProvider>
      <AccountProvider>{children}</AccountProvider>
    </AuthProvider>
  </ApiProvider>
);

const App = () => (
  <ErrorBoundary fallback={<h1>Something went wrong.</h1>}>
    <AppProviders>
      <BrowserRouter data-testid="BrowserRouter">
        <Switch>
          <Route path="/create-account" component={CreateAccount} />
          <Route path="/login" component={Login} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/intake" component={Intake} />
          <Route
            path="/oops"
            component={() => {
              console.log(process.env.NODE_ENV);
              console.log(process.env.REACT_APP_SENTRY_DSN);
              throw new Error('foobar');
            }}
          />
          <Route path="/view-waitlist/:id" component={WaitlistDetails} />
          <PrivateRoute path="/claim-account" component={ClaimAccount} />
          <PrivateRoute path="/complete-account" component={CompleteAccount} />
          <PrivateRoute path="/documents" component={Documents} />
          <PrivateRoute path="/hub" component={Hub} />
          <PrivateRoute path="/profile" component={Profile} />
          <PrivateRoute path="/notifications" component={Notifications} />
          <PrivateRoute path="/" component={BrowseWaitlists} />
        </Switch>
      </BrowserRouter>
    </AppProviders>
  </ErrorBoundary>
);

export default App;
