export const extractUserPreferences = context => {
  const {
    accessible_unit,
    mobility_device,
    disabled,
    domestic_violence,
    displaced,
    working_family,
    homeless,
    senior_citizen
  } = context;

  return {
    working_family,
    senior_citizen,
    mobility_device,
    homeless,
    domestic_violence,
    displaced,
    disabled,
    accessible_unit
  };
};

export const extractSearchParams = context => {
  const {
    household_size,
    household_income,
    disabled,
    locations,
    homeless,
    senior_citizen,
    preferred_bedroom_sizes
  } = context;

  return {
    household_size,
    household_income,
    senior_citizen,
    disabled,
    homeless,
    locations,
    preferred_bedroom_sizes
  };
};

export const groupWaitlistResultsDataByType = (results, waitlistKey) => {
  const formattedData = {};
  formattedData[waitlistKey] = results.data;
  formattedData['properties'] = results.included.filter(
    item => item.type === 'property'
  );
  formattedData['propertyImages'] = results.included.filter(
    item => item.type === 'property_image'
  );
  formattedData['locations'] = results.included.filter(
    item => item.type === 'location'
  );
  formattedData['documents'] = results.included.filter(
    item => item.type === 'document'
  );

  return formattedData;
};
