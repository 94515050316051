import React from 'react';
import Fields from 'components/Form/Fields';

export const fields = [
  {
    name: 'full_name',
    label: 'Full Name',
    input: 'input',
    type: 'text',
    placeholder: 'Full Name',
    containerClasses: ['form-group', 'col-md-6'],
    classes: ['form-control'],
    describedBy: 'full_name'
  },
  {
    name: 'preferred_name',
    label: 'Preferred Name',
    input: 'input',
    type: 'text',
    placeholder: 'Preferred Name',
    containerClasses: ['form-group', 'col-md-6'],
    classes: ['form-control'],
    describedBy: 'preferred_name'
  },
  {
    name: 'email',
    label: 'Email Address',
    input: 'input',
    type: 'email',
    placeholder: 'Email Address',
    containerClasses: ['form-group', 'col-md-6'],
    classes: ['form-control'],
    describedBy: 'email'
  },
  {
    name: 'confirm_email',
    label: 'Confirm Email Address',
    input: 'input',
    type: 'email',
    placeholder: 'Confirm Email Address',
    containerClasses: ['form-group', 'col-md-6'],
    classes: ['form-control'],
    describedBy: 'confirm_email'
  },
  {
    name: 'password',
    label: 'Password',
    input: 'input',
    type: 'password',
    placeholder: 'Password',
    containerClasses: ['form-group', 'col-md-6'],
    classes: ['form-control'],
    describedBy: 'password'
  },
  {
    name: 'confirm_password',
    label: 'Confirm Password',
    input: 'input',
    type: 'password',
    placeholder: 'Confirm Password',
    containerClasses: ['form-group', 'col-md-6'],
    classes: ['form-control'],
    describedBy: 'confirm_password'
  }
];

const UserNamePassword = props => (
  <div className="row">
    <div className="col-md-10 m-auto">
      <h2 className="text-center mb-3">Name, Email and Password</h2>
      <div className="row">
        <Fields inputs={fields} {...props} />
      </div>
    </div>
  </div>
);

export default UserNamePassword;
