import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { handleJSONResponse, apiURL } from 'utils/api';
import OutsideClick from 'components/OutsideClick';
import GlobalAlert from 'components/Alert/GlobalAlert';

class LocationLookup extends Component {
  state = {
    locationLookupInputValue: '',
    results: [],
    loading: false,
    alert: {
      show: false,
    },
  };

  setLocation = (e, location) => {
    e.preventDefault();
    if (location.disabled) {
      return null;
    }

    this.handleClearInput();
    this.props.update({
      target: {
        type: 'text',
        value: [...(this.props.formValues.locations ?? []), location],
        name: 'locations',
      },
    });
  };

  handleSearchQuery = () => {
    this.setState({ loading: true });

    fetch(apiURL(`/place_query?query=${this.state.locationLookupInputValue}`))
      .then(resp => handleJSONResponse(resp))
      .then(results => {
        results = results.data.reduce((arr, result) => {
          const resultIsSelected = this.props.formValues.locations?.find(
            location => location.id === result.id
          );
          if (resultIsSelected) {
            result.disabled = true;
          }

          return [...arr, result];
        }, []);

        this.setState({
          results,
          loading: false,
        });
      })
      .catch(err => {
        let msg =
          'Sorry, there was an error with the request. Please try again later.';
        if (err.errors) {
          msg = err.errors.map(e => e).join(', ');
        }
        this.setState({
          loading: false,
          alert: {
            show: true,
            type: 'warning',
            msg,
            extraClasses: ['text-center', 'text-white'],
          },
        });
      });
  };

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (value.match(/\d/)) {
      this.setState({
        alert: {
          show: true,
          type: 'warning',
          msg: 'Cannot contain numbers.',
          extraClasses: ['text-center', 'text-white'],
        },
      });

      return false;
    }

    this.setState(
      {
        [name]: value,
        alert: {},
      },
      () => {
        if (this.state.locationLookupInputValue.length > 2) {
          this.handleSearchQuery();
        }
      }
    );
  };

  handleClearInput = () => {
    this.setState({
      locationLookupInputValue: '',
      results: [],
    });
    this.props.touched && this.props.touched('locations');
  };

  render() {
    return (
      <React.Fragment>
        {this.state.alert.show && <GlobalAlert {...this.state.alert} />}

        <div className="input-group">
          <input
            type="text"
            placeholder="State, County or City Name"
            name="locationLookupInputValue"
            id="location-lookup"
            className={`form-control ${
              this.props.formErrors['locations'] ? 'is-invalid' : ''
            }`}
            aria-describedby="location-lookup-label"
            aria-label="location lookup"
            autoComplete="off"
            onChange={this.handleInputChange}
            onBlur={e => this.props.touched && this.props.touched('locations')}
            value={this.state.locationLookupInputValue}
          />
          {this.state.loading && (
            <div className="input-group-append">
              <span className="input-group-text">
                <FontAwesomeIcon icon="circle-notch" spin />
                <span className="sr-only">Searching</span>
              </span>
            </div>
          )}
          {this.props.formErrors['locations'] && (
            <div className="invalid-feedback">
              {this.props.formErrors['locations']}
            </div>
          )}
        </div>
        {this.state.results.length > 0 && (
          <OutsideClick action={this.handleClearInput}>
            <div className="location-lookup-results list-group">
              {this.state.results.map(result => (
                <div
                  key={`result-${result.id}`}
                  className={`list-group-item list-group-item-action flex-column align-items-start ${
                    result.disabled ? 'disabled' : ''
                  }`}
                  onClick={e => this.setLocation(e, result)}
                  onKeyPress={e => this.setLocation(e, result)}
                  role="link"
                  tabIndex="0"
                >
                  <div className="d-flex w-100 justify-content-between">
                    <h6 className="mb-0">{result.attributes.long_name}</h6>
                    <small>
                      {result.disabled ? (
                        <em>already selected</em>
                      ) : (
                        result.attributes.category
                      )}
                    </small>
                  </div>
                </div>
              ))}
            </div>
          </OutsideClick>
        )}
      </React.Fragment>
    );
  }
}

LocationLookup.propTypes = {
  update: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
};

export default LocationLookup;
