import React, { Component } from 'react';
import { handleJSONResponse, apiURL, getToken } from 'utils/api';
import EmptyState from 'components/EmptyState';
import { notification } from 'actions';
import NotificationCard from './NotificationCard';
import Loader from 'react-loader-spinner';

class NotificationList extends Component {
  state = {
    notifications: [],
    loading: false,
  };

  componentDidMount() {
    this.handleFetchNotifications();
  }

  handleFetchNotifications() {
    // TODO: Get the loading state from the action
    this.setState({
      loading: true,
    });

    const { applicantId, handleAlert } = this.props;
    const JWT = getToken();

    fetch(apiURL(`/notifications?applicant_id=${applicantId}`), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...JWT.token,
      },
    })
      .then(resp => handleJSONResponse(resp, JWT.storageMethod))
      .then(resp => {
        this.setState({ notifications: resp.data });
      })
      .catch(err => {
        let msg = ['Sorry, something went wrong.'];
        if (err.errors) {
          msg = err.errors.map(e => e).join(', ');
        }

        handleAlert({
          show: true,
          extraClasses: ['text-white', 'text-center'],
          dismissable: true,
          removeAlert: () => handleAlert({}),
          msg,
          type: 'danger',
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    if (this.state.loading) {
      return (
        <EmptyState>
          <h3>
            <Loader
              className="loader-container"
              type="Oval"
              color="#00BFFF"
              height={22}
              width={22}
            />
            <span>Loading Notifications...</span>
          </h3>
        </EmptyState>
      );
    }

    if (this.state.notifications.length === 0) {
      return (
        <EmptyState>
          <h3>You have not received any notifications yet.</h3>
        </EmptyState>
      );
    }

    const allUnreadNotifications = this.state.notifications
      .filter(notification => {
        return notification.attributes.marked_as_read === false;
      })
      .sort((a, b) => {
        let a_offer = a.attributes.is_offer_notification;
        let b_offer = b.attributes.is_offer_notification;
        if (a_offer && !b_offer) {
          return -1;
        }
        if (!a_offer && b_offer) {
          return 1;
        }
        return 0;
      });

    const readNotifications = this.state.notifications.filter(notification => {
      return notification.attributes.marked_as_read === true;
    });

    return (
      <React.Fragment>
        <h2 className="mb-3">New Notifications</h2>
        {allUnreadNotifications.length === 0 ? (
          <EmptyState>
            <h3>No new notifications.</h3>
          </EmptyState>
        ) : (
          allUnreadNotifications.map(n => (
            <NotificationCard
              key={n.id}
              notification={n}
              handleChangeReadStatus={notification.handleMarkAsRead}
              buttonText={'Mark as Read'}
              handleFetchNotifications={this.handleFetchNotifications.bind(
                this
              )}
              {...this.props}
            />
          ))
        )}
        {!!this.props.showRead && (
          <React.Fragment>
            <h2 className="my-3">Read Notifications</h2>
            {readNotifications.length === 0 ? (
              <EmptyState>
                <h3>No notifications marked as read.</h3>
              </EmptyState>
            ) : (
              readNotifications.map(n => (
                <NotificationCard
                  key={n.id}
                  notification={n}
                  handleChangeReadStatus={notification.handleMarkAsUnread}
                  buttonText={'Mark as Unread'}
                  handleFetchNotifications={this.handleFetchNotifications.bind(
                    this
                  )}
                  {...this.props}
                />
              ))
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

NotificationList.defaultProps = {
  showRead: true,
};

export default NotificationList;
