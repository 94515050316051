import React from 'react';
import { AuthContext } from 'screens/Login/Data/Provider';
import { AccountContext } from 'screens/CreateAccount/Data/Provider';
import { UserProfileContext } from 'screens/Profile/Data/Provider';
import GlobalAlert from 'components/Alert/GlobalAlert';

const AlertHandler = () => {
  return (
    <React.Fragment>
      <AuthContext.Consumer>
        {authContext => (
          <React.Fragment>
            {authContext.alert.show && <GlobalAlert {...authContext.alert} />}
            {authContext.loggedIn && (
              <UserProfileContext.Consumer>
                {profileContext =>
                  profileContext.alert.show && (
                    <GlobalAlert {...profileContext.alert} />
                  )
                }
              </UserProfileContext.Consumer>
            )}
          </React.Fragment>
        )}
      </AuthContext.Consumer>
      <AccountContext.Consumer>
        {accountContext =>
          accountContext.alert.show && <GlobalAlert {...accountContext.alert} />
        }
      </AccountContext.Consumer>
    </React.Fragment>
  );
};

export default AlertHandler;
