import React, { Component } from 'react';
import { handleJSONResponse, apiURL, getToken, dropToken } from 'utils/api';

export const AuthContext = React.createContext();

class AuthProvider extends Component {
  constructor() {
    super();

    this.defaultAlert = {
      show: true,
      extraClasses: ['text-white', 'text-center'],
      dismissable: true,
      removeAlert: () => this.handleAlert({}),
    };

    this.state = {
      loaded: false,
      loggedIn: false,
      user: {},
      msg: null,
      alert: {},
      redirectPath: null,
    };
  }

  componentDidMount() {
    this.handleRestoreSession();
  }

  handleAlert = alert => {
    this.setState({
      alert,
    });
  };

  logUserIn = (resp, alert) => {
    const { reqHeader, errors, ...user } = resp;

    this.setState(
      {
        loggedIn: true,
        loaded: true,
        user: user.data,
        redirectPath: user.data.redirect_path ? user.data.redirect_path : null,
      },
      () => {
        if (alert && !errors) {
          this.handleAlert({
            ...this.defaultAlert,
            msg: 'You are now logged in.',
            type: 'success',
            timeout: 3000,
          });
        } else if (errors && errors.length > 0) {
          this.handleAlert({
            ...this.defaultAlert,
            type: 'danger',
            msg: errors.join(', '),
          });
        }
      }
    );
  };

  handleRestoreSession = () => {
    const JWT = getToken();

    fetch(apiURL('/auth/validate_token'), {
      method: 'GET',
      headers: {
        ...JWT.token,
      },
    })
      .then(resp => handleJSONResponse(resp, JWT.storageMethod))
      .then(resp => this.logUserIn(resp))
      .catch(() => {
        this.handleLogOutUser();
      });
  };

  handleLogInUser = values => {
    let storageMethod = sessionStorage;
    if (values.rememberMe) {
      storageMethod = localStorage;
    }

    fetch(apiURL('/auth/sign_in'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: values.emailAddress,
        password: values.password,
        redirectPath: values.redirectPath,
      }),
    })
      .then(resp => handleJSONResponse(resp, storageMethod))
      .then(resp => this.logUserIn(resp, true))
      .catch(err => {
        const msg = err.errors.full_messages
          ? err.errors.full_messages.join(', ')
          : err.errors.join(', ');

        this.handleAlert({
          ...this.defaultAlert,
          msg,
          type: 'danger',
        });
      });
  };

  handleLogInFromToken = token =>
    fetch(apiURL('/auth/validate_token'), {
      method: 'GET',
      headers: {
        ...token,
      },
    })
      .then(resp => handleJSONResponse(resp, sessionStorage))
      .then(resp => this.logUserIn(resp, true))
      .catch(() => {
        this.handleLogOutUser(true);
      });

  handleLogOutUser = alert => {
    dropToken();
    this.setState(
      {
        loggedIn: false,
        loaded: true,
      },
      () => {
        if (alert) {
          this.handleAlert({
            ...this.defaultAlert,
            type: 'success',
            msg: 'You are now logged out.',
            timeout: 3000,
          });
        }
      }
    );
  };

  handleSendPasswordReset = values =>
    fetch(apiURL('/auth/password'), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: values.emailAddress,
        redirect_url: `${window.location.origin}/reset-password`,
      }),
    })
      .then(resp => handleJSONResponse(resp))
      .then(resp =>
        this.handleAlert({
          ...this.defaultAlert,
          type: 'success',
          msg: 'You will receive an email with instructions on how to reset your password in a few minutes.',
          timeout: 3000,
        })
      )
      .catch(err => {
        this.handleAlert({
          ...this.defaultAlert,
          type: 'danger',
          msg: err.errors.join(', '),
          timeout: 3000,
        });
      });

  handleSetNewPassword = (values, token) =>
    fetch(apiURL('/auth/password'), {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        ...token,
      },
      body: JSON.stringify(values),
    })
      .then(resp => handleJSONResponse(resp, sessionStorage))
      .then(resp => this.logUserIn(resp, true))
      .catch(err => {
        let msg = 'Password reset failed.';
        if (err.errors) {
          msg = err.errors.full_messages
            ? err.errors.full_messages.join(', ')
            : err.errors.join(', ');
        }
        this.handleLogOutUser();
        this.handleAlert({
          ...this.defaultAlert,
          type: 'danger',
          msg,
          timeout: 3000,
        });
      });

  render() {
    const cxt = {
      logInUser: this.handleLogInUser,
      logInFromToken: this.handleLogInFromToken,
      logOutUser: () => this.handleLogOutUser(true),
      sendPasswordReset: this.handleSendPasswordReset,
      setNewPassword: this.handleSetNewPassword,
      ...this.state,
    };

    return (
      <AuthContext.Provider value={cxt}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

export default AuthProvider;
