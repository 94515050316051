import 'react-app-polyfill/ie11';

import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import App from 'screens/App';
import { unregister as unregisterServiceWorker } from 'utils/registerServiceWorker';
import 'styles/main.scss';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: process.env.REACT_APP_SENTRY_SAMPLE_RATE || 0.1,
  enabled: process.env.NODE_ENV !== 'development',
});

ReactDOM.render(<App />, document.getElementById('root'));
unregisterServiceWorker();
