import React from 'react';
import { get } from 'utils/env';

// WARNING changes to this file must also be made in housing_hub/app/javascript/components/Waitlists/WaitlistDocuments
const WaitlistDocuments = ({ documents }) => {
  const document_url_prefix =
    process.env.REACT_APP_ENV === 'production' ||
    process.env.REACT_APP_ENV === 'staging'
      ? ''
      : get('manager_app_root_url');

  return (
    <ul className="pl-4 mt-3">
      <div className="row mx-0">
        {documents.map(document => {
          return (
            <div key={document.attributes.title} className="col-lg-6">
              <li>
                <a
                  href={`${document_url_prefix}${document.attributes.document_url}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {document.attributes.title}
                </a>
              </li>
            </div>
          );
        })}
      </div>
    </ul>
  );
};

export default WaitlistDocuments;
