import React from 'react';
import Popover from 'components/Popover';

class EstimatedWait extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popovers: {
        estimatedWait: false,
      },
    };
  }

  togglePopover = e => {
    e.preventDefault();

    this.setState(prevState => {
      return {
        popovers: {
          ...prevState.popovers,
          estimatedWait: !prevState.popovers['estimatedWait'],
        },
      };
    });
  };

  render() {
    return (
      <Popover
        trigger={
          <div
            role="button"
            tabIndex="0"
            className="badge-wrap mb-3"
            onMouseEnter={e => this.togglePopover(e)}
            onMouseLeave={e => this.togglePopover(e)}>
            <span className="badge left-badge black">Estimated Wait</span>
            <span className="badge right-badge">{this.props.waitTime}</span>
          </div>
        }
        show={this.state.popovers.estimatedWait}
        header="">
        <React.Fragment>
          <p>
            Estimated time from when you joined the list until you’re invited to
            apply.
          </p>
        </React.Fragment>
      </Popover>
    );
  }
}

export default EstimatedWait;
