import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class DocumentUpload extends Component {
  constructor(props) {
    super(props);
    this.titleInput = React.createRef();
    this.state = {
      file: null
    };
  }

  handleSubmit(e) {
    e.preventDefault();

    const {
      applicantId,
      handleDocumentUpload,
      alertHandler,
      fetchUserHandler
    } = this.props;
    const documentUploadProps = {
      applicantId,
      handleAlert: alertHandler,
      fetchUserHandler,
      file: this.state.file,
      title: this.titleInput.current.value,
      form: e.target,
      clearFileHandler: this.clearFile.bind(this)
    };

    handleDocumentUpload(documentUploadProps);
  }

  handleDrop(file) {
    this.setState({ file: file[0] });
  }

  clearFile() {
    this.setState({ file: null });
  }

  render() {
    return (
      <React.Fragment>
        <h3 className="my-4">Upload Documents</h3>
        <form id="upload-doc-form" onSubmit={this.handleSubmit.bind(this)}>
          {!this.state.file ? (
            <Dropzone onDrop={this.handleDrop.bind(this)}>
              {({ getRootProps, getInputProps }) => (
                <div className="mt-4 row mx-0">
                  <div className="col-md-6 px-0 document-upload-dropzone">
                    <div
                      {...getRootProps()}
                      className="p-5"
                      aria-label="Drag a document or click here to attach">
                      <input {...getInputProps()} />
                      <div className="d-flex justify-content-center">
                        <FontAwesomeIcon icon="paperclip" size="2x" />
                      </div>
                      <h4 className="text-center p-3">
                        Drag a document or click here to attach
                      </h4>
                    </div>
                  </div>
                </div>
              )}
            </Dropzone>
          ) : (
            <React.Fragment>
              <h6>File: </h6>
              <div className="d-flex">
                <p className="mb-0">{this.state.file.path}</p>
                <button
                  className="red-link px-5"
                  onClick={this.clearFile.bind(this)}>
                  Clear file
                </button>
              </div>
            </React.Fragment>
          )}
          <div className="mt-4">
            <label
              htmlFor="applicant_document_title"
              className="font-weight-bold mr-3">
              Give your document a title (optional):
            </label>
            <input
              type="text"
              name="applicant_document_title"
              id="applicant_document_title"
              ref={this.titleInput}
            />
          </div>
          <button
            className="btn btn-blue px-5 my-4"
            disabled={!this.state.file}>
            Upload
          </button>
        </form>
      </React.Fragment>
    );
  }
}

export default DocumentUpload;
