import React, { Component } from 'react';
import { AuthContext } from 'screens/Login/Data/Provider';
import { UserProfileContext } from 'screens/Profile/Data/Provider';
import { document } from 'actions';
import Page from 'components/Page';
import Sidebar from 'components/Sidebar/Hub';
import DocumentList from 'components/Documents/DocumentList';
import DocumentUpload from 'components/Documents/DocumentUpload';

class Documents extends Component {
  state = {
    documents: []
  };

  render() {
    return (
      <Page>
        <h1 className="mt-4 mb-3 pb-3">Documents</h1>
        <div className="row">
          <div className="col-md-3">
            <Sidebar />
          </div>
          <div className="col-md-8">
            <AuthContext.Consumer>
              {authContext =>
                authContext.loaded &&
                authContext.loggedIn && (
                  <UserProfileContext.Consumer>
                    {userContext =>
                      !userContext.isSet ? null : (
                        <React.Fragment>
                          <DocumentList
                            documents={userContext.user.documents}
                            applicantId={authContext.user.id}
                            handleDelete={document.handleDelete}
                            handleDownload={document.handleDownload}
                            alertHandler={userContext.alertHandler}
                            fetchUserHandler={userContext.fetchUserHandler}
                          />
                          <DocumentUpload
                            applicantId={authContext.user.id}
                            handleDocumentUpload={document.handleDocumentUpload}
                            alertHandler={userContext.alertHandler}
                            fetchUserHandler={userContext.fetchUserHandler}
                          />
                        </React.Fragment>
                      )
                    }
                  </UserProfileContext.Consumer>
                )
              }
            </AuthContext.Consumer>
          </div>
        </div>
      </Page>
    );
  }
}

export default Documents;
