import React from 'react';
import Page from 'components/Page';
import Sidebar from 'components/Sidebar/Hub';
import { AuthContext } from 'screens/Login/Data/Provider';
import { UserProfileContext } from 'screens/Profile/Data/Provider';
import { extractSearchParams } from 'utils/waitlistSearch';
import WaitlistSearch from 'components/Waitlists/WaitlistSearch';
import HubWaitlists from '../../components/Waitlists/HubWaitlists';
import NotificationList from '../../components/Notifications/NotificationList';

const Hub = () => {
  return (
    <Page>
      <h1 className="mt-4 mb-3 pb-3">My Hub</h1>
      <div className="row">
        <div className="col-md-3">
          <Sidebar />
        </div>
        <div className="col-md-8">
          <AuthContext.Consumer>
            {authContext =>
              authContext.loaded &&
              authContext.loggedIn && (
                <UserProfileContext.Consumer>
                  {userContext =>
                    !userContext.isSet ? null : (
                      <React.Fragment>
                        <NotificationList
                          applicantId={authContext.user.id}
                          handleAlert={userContext.alertHandler}
                          showRead={false}
                        />
                        <WaitlistSearch
                          searchParams={extractSearchParams(userContext.user)}
                          handleAlert={userContext.alertHandler}
                          joinedWaitlists={
                            userContext.user.waitlist_applicants || []
                          }
                          component={HubWaitlists}
                          applicantId={authContext.user.id}
                          fetchUserHandler={userContext.fetchUserHandler}
                        />
                      </React.Fragment>
                    )
                  }
                </UserProfileContext.Consumer>
              )
            }
          </AuthContext.Consumer>
        </div>
      </div>
    </Page>
  );
};

export default Hub;
