import { DEVELOPMENT, STAGING, PRODUCTION } from 'config';

export function get(keyname) {
  let config;

  switch (process.env.REACT_APP_ENV) {
    case 'production':
      config = PRODUCTION;
      break;
    case 'staging':
      config = STAGING;
      break;
    default:
      config = DEVELOPMENT;
      break;
  }

  return config[keyname];
}
