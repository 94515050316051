import React from 'react';
import { ApiContext } from './Data/Provider';
import FullPageMessage from 'components/Alert/FullPageMessage';

const ApiReady = props => {
  return (
    <ApiContext.Consumer>
      {contextState =>
        !contextState.apiReady && contextState.isLoaded ? (
          <FullPageMessage {...contextState.msg} />
        ) : (
          props.children
        )
      }
    </ApiContext.Consumer>
  );
};

export default ApiReady;
