import { handleJSONResponse, apiURL, getToken } from 'utils/api';

const handleDocumentUpload = ({
  applicantId,
  file,
  title,
  handleAlert,
  fetchUserHandler,
  clearFileHandler,
  form
}) => {
  const JWT = getToken();
  const data = new FormData();
  data.append('applicant_id', applicantId);
  data.append('file', file);
  data.append('title', title);

  fetch(apiURL('/applicant_documents/'), {
    method: 'POST',
    headers: {
      ...JWT.token
    },
    body: data
  })
    .then(resp => handleJSONResponse(resp, JWT.storageMethod))
    .then(resp => {
      let msg = resp.success;

      handleAlert({
        show: true,
        extraClasses: ['text-white', 'text-center'],
        dismissable: true,
        removeAlert: () => handleAlert({}),
        msg,
        type: 'success'
      });

      fetchUserHandler();
      clearFileHandler();
      form.reset();
    })
    .catch(err => {
      let msg = ['Sorry, something went wrong.'];
      if (err.errors) {
        msg = err.errors.map(e => e).join(', ');
      }

      handleAlert({
        show: true,
        extraClasses: ['text-white', 'text-center'],
        dismissable: true,
        removeAlert: () => handleAlert({}),
        msg,
        type: 'danger'
      });
    });
};

const handleDownload = ({ applicantId, privateFileUrl, handleAlert }) => {
  const JWT = getToken();

  return fetch(apiURL(`${privateFileUrl}?applicant_id=${applicantId}`), {
    method: 'GET',
    headers: {
      ...JWT.token
    }
  })
    .then(resp => handleJSONResponse(resp, JWT.storageMethod))

    .catch(err => {
      let msg = ['Sorry, something went wrong.'];
      if (err.errors) {
        msg = err.errors.map(e => e).join(', ');
      }

      handleAlert({
        show: true,
        extraClasses: ['text-white', 'text-center'],
        dismissable: true,
        removeAlert: () => handleAlert({}),
        msg,
        type: 'danger'
      });
    });
};

const handleDelete = ({
  applicantId,
  documentId,
  handleAlert,
  fetchUserHandler
}) => {
  const JWT = getToken();

  fetch(apiURL(`/applicant_documents/${documentId}`), {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      ...JWT.token
    },
    body: JSON.stringify({
      applicant_id: applicantId
    })
  })
    .then(resp => handleJSONResponse(resp, JWT.storageMethod))
    .then(resp => {
      let msg = resp.success;

      handleAlert({
        show: true,
        extraClasses: ['text-white', 'text-center'],
        dismissable: true,
        removeAlert: () => handleAlert({}),
        msg,
        type: 'success'
      });

      fetchUserHandler();
    })
    .catch(err => {
      let msg = ['Sorry, something went wrong.'];
      if (err.errors) {
        msg = err.errors.map(e => e).join(', ');
      }

      handleAlert({
        show: true,
        extraClasses: ['text-white', 'text-center'],
        dismissable: true,
        removeAlert: () => handleAlert({}),
        msg,
        type: 'danger'
      });
    });
};

const document = { handleDocumentUpload, handleDelete, handleDownload }

export default document;
