// Define pattern to test field name
const email = /email/i;
const password = /password/i;
const confirm = /confirm/i;
const alt_confirm = /alt_confirm/i;
const phone = /phone/i;
const zip = /zip/i;

const numbersOnly = val => val.replace(/[^0-9]/g, '');

export const formatPhoneNumber = val => {
  const newVal = numbersOnly(val);
  const str = newVal.length;

  switch (true) {
    case str > 3 && str < 7:
      return `${newVal.slice(0, 3)}-${newVal.slice(3, 6)}`;
    case str > 6:
      return `${newVal.slice(0, 3)}-${newVal.slice(3, 6)}-${newVal.slice(6)}`;
    default:
      return newVal;
  }
};

const formatZipCode = val => {
  const newVal = numbersOnly(val);
  return newVal.length > 5
    ? `${newVal.slice(0, 5)}-${newVal.slice(5, 9)}`
    : newVal.slice(0, 5);
};

export const evaluateKeyPress = event => {
  const target = event.target;
  const name = target.name;
  let value = target.value;

  switch (true) {
    case phone.test(name):
      event.target.value = formatPhoneNumber(value);
      break;
    case zip.test(name):
      event.target.value = formatZipCode(value);
      break;
    default:
      return event;
  }

  return event;
};

export const evaluateError = (form, field, requiredFields) => {
  let errMessage = '';
  if (!(field in form)) {
    return null;
  }

  // requiredFields maps required fields to their original empty value.
  if (
    requiredFields &&
    field in requiredFields &&
    (form[field] == null ||
      form[field] === requiredFields[field] ||
      (Array.isArray(form[field]) && !form[field].length))
  ) {
    return 'required';
  }

  switch (true) {
    case email.test(field):
      const regex = /\S+@\S+\.\S+/; // loose verification of anystring@anystring.anystring string
      if (confirm.test(field)) {
        errMessage =
          form.email !== form[field] && 'Email addresses do not match';
      } else {
        errMessage =
          !regex.test(form[field]) &&
          'This does not appear to be an email address';
      }
      break;
    case password.test(field):
      if (confirm.test(field)) {
        errMessage = form.password !== form[field] && 'Passwords do not match';
      } else {
        errMessage = form[field].length < 6 && 'Password too short';
      }
      break;
    case phone.test(field):
      if (alt_confirm.test(field)) {
        errMessage =
          form.alt_phone_number !== form[field] && 'Phone numbers do not match';
      } else if (confirm.test(field)) {
        errMessage =
          form.phone_number !== form[field] && 'Phone numbers do not match';
      }
      break;
    default:
      errMessage = '';
  }

  return errMessage;
};

// Returns true if all values in formValues have changed from their
// corresponding value in initialValues (if they have one). In practice, used to
// validate if all required fields are present by mapping the keys in
// initialValues to empty values such as the empty string.
export const validate = (formValues, initialValues) => {
  if (!initialValues) {
    return true;
  }

  // Reduce list to just the required values.
  const testableValues = Object.keys(formValues).reduce((acc, key) => {
    const fieldIsRequired = Object.keys(initialValues).find(
      required => required === key
    );
    return fieldIsRequired ? { ...acc, [key]: formValues[key] } : acc;
  }, {});

  // Return false if any required value is unchanged from the value in
  // initialvalues.
  return !Object.keys(testableValues).some(value => {
    let inputValue = testableValues[value];
    let defaultValue = initialValues[value];

    if (Array.isArray(inputValue)) {
      inputValue = testableValues[value].length;
      defaultValue = initialValues[value].length;
    }

    return inputValue === defaultValue;
  });
};
