import React, { Component } from 'react';
import GlobalNavigation from 'components/GlobalNavigation';
import ApiReady from 'components/Api';
import ProviderAlert from 'components/Alert/ProviderAlert';
import Footer from './Footer';

class Page extends Component {
  render() {
    return (
      <ApiReady>
        <GlobalNavigation />
        <ProviderAlert />

        {this.props.header && this.props.header}
        <div className="container">{this.props.children}</div>
        <Footer />
      </ApiReady>
    );
  }
}

export default Page;
