import React, { Component } from 'react';
import { UserProfileContext } from './Data/Provider';
import Page from 'components/Page';
import Sidebar from 'components/Sidebar/Hub';
import ProfileFields from './Fields';
import ConfirmAddressModal from './ConfirmAddressModal';
import ConfirmChangesModal from './ConfirmChangesModal';

class Profile extends Component {
  state = {
    popovers: {
      deleteAltContact: false,
    },
    verifyFieldsModal: false,
    canSubmit: true,
  };

  handleTogglePopover = (e, popover) => {
    e.preventDefault();

    this.setState(prevState => {
      return {
        popovers: {
          ...prevState.popovers,
          [popover]: !prevState.popovers[popover],
        },
      };
    });
  };

  setCanSubmit = canSubmit => {
    this.setState({
      canSubmit,
    });
  };

  openModal = () => {
    this.setState({
      verifyFieldsModal: true,
    });
  };

  closeModal = () => {
    this.setState({
      verifyFieldsModal: false,
    });
  };

  render() {
    return (
      <Page>
        <UserProfileContext.Consumer>
          {userContext => (
            <React.Fragment>
              <div className="row mt-4 mb-3 pb-3">
                <div className="col-10">
                  <h1>My Profile</h1>
                </div>
                <div className="col-12 col-md-2 d-flex align-items-end">
                  <button
                    disabled={!this.state.canSubmit}
                    className="btn btn-blue btn-block"
                    onClick={this.openModal}
                  >
                    Save
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <Sidebar />
                </div>
                <div className="col-md-9">
                  <ProfileFields
                    profile={userContext}
                    popovers={this.state.popovers}
                    togglePopover={this.handleTogglePopover}
                    delete={(e, method) => {
                      userContext[method](e);
                      this.handleTogglePopover(e, method);
                    }}
                    canSubmit={this.setCanSubmit}
                  />
                  <div className="row mt-3 mb-5">
                    <div className="col-8 mx-auto">
                      <button
                        disabled={!this.state.canSubmit}
                        className="btn btn-green btn-block"
                        onClick={this.openModal}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {this.state.verifyFieldsModal && (
                <ConfirmAddressModal
                  user={userContext.user}
                  accept={values => userContext.confirmEligibility(values)}
                  close={this.closeModal}
                />
              )}
              {userContext.disqualifiedWaitlists && (
                <ConfirmChangesModal
                  waitlistRemovals={userContext.disqualifiedWaitlists}
                  user={userContext.user}
                  accept={userContext.save}
                  fetchUserHandler={userContext.fetchUserHandler}
                  close={userContext.closeEligibilityModal}
                />
              )}
            </React.Fragment>
          )}
        </UserProfileContext.Consumer>
      </Page>
    );
  }
}

export default Profile;
