import React from 'react';
import PropertyImagePlaceholder from 'images/property-placeholder.png';

const PropertyCardSummary = props => {
  const { property } = props;

  const propertyAttributes = {
    unit_groups: 'Unit Sizes',
    preferences: 'Preferences',
    accessibility_features: 'Accessibility Features',
    amenities: 'Amenities'
  };

  const formatPropertyDetails = attributes => {
    if (attributes.length < 3) {
      return attributes.join(', ');
    }

    return attributes.slice(0, 2).join(', ') + ', more...';
  };

  if (property) {
    return (
      <div>
        <div className="row">
          <div className="col-md-3 d-flex align-items-center">
            {property.associatedPropertyImages.length === 0 ? (
              <img
                src={PropertyImagePlaceholder}
                alt="property"
                style={{ width: '100%' }}
              />
            ) : (
              <img
                src={
                  property.featuredPropertyImage[0]
                    ? property.featuredPropertyImage[0].attributes.image_url
                    : property.associatedPropertyImages[0].attributes.image_url
                }
                alt="property"
                style={{ width: '100%' }}
              />
            )}
          </div>

          <div className="col-9">
            <h4 className="mb-2">{property.attributes.name}</h4>
            {property.attributes.building_types.length > 0 && (
              <p className="mb-0">
                <span className="font-weight-bold">Building Types: </span>
                {property.attributes.building_types.join(', ')}
              </p>
            )}
            <div className="d-flex">
              <h6 className="mr-1 mt-1">Address: </h6>
              <p>
                {property.associatedLocations.length === 0 ? (
                  <span>
                    {property.attributes.street_address}
                    <br />
                    {property.attributes.city}, {property.attributes.state}{' '}
                    {property.attributes.zip_code}
                    <br />
                    {property.attributes.county}
                  </span>
                ) : (
                  property.associatedLocations.map((location, index) => (
                    <span key={`location-${location.id}-${index}`}>
                      {location.attributes.short_name}
                      {index === property.associatedLocations.length - 1
                        ? ''
                        : ', '}
                    </span>
                  ))
                )}
              </p>
            </div>

            <div className="d-none d-sm-block">
              {Object.keys(propertyAttributes).map(attribute => {
                if (property.attributes[attribute].length > 0) {
                  return (
                    <p key={attribute} className="mb-0">
                      <span className="font-weight-bold">
                        {propertyAttributes[attribute]}:{' '}
                      </span>
                      {formatPropertyDetails(property.attributes[attribute])}
                    </p>
                  );
                }
                return null;
              })}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <h3>Loading property data...</h3>;
  }
};

export default PropertyCardSummary;
