import React, { Component } from 'react';
import Modal from 'components/Modal';
import PropTypes from 'prop-types';
import isEqual from 'lodash.isequal';
import { getAddressFields } from 'utils/fields';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { user } from 'actions';

class ConfirmAddressModal extends Component {
  state = {
    error: null,
    alt_error: null,
    fieldsForSubmit: {},
    isLoaded: false
  };

  componentDidMount() {
    user
      .fieldsForSubmit({ formState: this.props.user })
      .then(fieldsForSubmit => {
        const { error, alt_error, ...values } = fieldsForSubmit;
        return {
          error,
          alt_error,
          fieldsForSubmit: values
        };
      })
      .then(fields => {
        this.setState({
          ...fields
        });

        return fields;
      })
      .then(fields => {
        const { fieldsForSubmit, error, alt_error } = fields;
        const currentAddresses = getAddressFields(this.props.user);
        const processedAddresses = getAddressFields(fieldsForSubmit);

        if (
          isEqual(currentAddresses, processedAddresses) &&
          !error &&
          !alt_error
        ) {
          this.handleAccept();
          return null;
        }

        this.setState({
          isLoaded: true
        });
      });
  }

  closeModal = () => {
    this.props.close();
  };

  handleAccept = () => {
    this.closeModal();
    this.props.accept(this.state.fieldsForSubmit);
  };

  render() {
    const { fieldsForSubmit, error, alt_error, isLoaded } = this.state;
    const { user } = this.props;

    const addressBlock = obj => (
      <address>
        {Object.keys(obj)
          .filter(attr => fieldsForSubmit[attr] !== '')
          .map(attr => (
            <span key={attr}>
              {fieldsForSubmit[attr]}
              <br />
            </span>
          ))}
      </address>
    );

    const warning = msg => (
      <div className="alert alert-warning text-center">
        <FontAwesomeIcon icon="exclamation-triangle" /> {msg}
      </div>
    );

    if (!isLoaded) {
      return null;
    }

    return (
      <Modal isOpen>
        {((error && error.source === 'server') ||
          (alt_error && alt_error.source === 'server')) &&
          warning(error.message || alt_error.message)}

        <h2>Verify USPS Address</h2>
        <p>Please confirm the addresses below or cancel to change.</p>
        <div className="row">
          <div className="col">
            {error && error.source === 'user' && warning(error.message)}
            <h4>Primary Address</h4>
            {addressBlock(getAddressFields(fieldsForSubmit).primary)}
          </div>
          {(user.include_alt_contact === true ||
            user.include_alt_contact === 'yes') &&
            user.alt_contact_method.value === 'mail' && (
              <div className="col">
                {alt_error &&
                  alt_error.source === 'user' &&
                  warning(alt_error.message)}
                <h4>Alternative Address</h4>
                {addressBlock(getAddressFields(fieldsForSubmit).alt)}
              </div>
            )}
        </div>
        <div className="d-flex w-100 justify-content-between">
          <button
            className="btn btn-outline-secondary"
            onClick={this.closeModal}>
            Cancel
          </button>
          <button className="btn btn-green" onClick={this.handleAccept}>
            Accept & Save
          </button>
        </div>
      </Modal>
    );
  }
}

ConfirmAddressModal.propTypes = {
  user: PropTypes.object.isRequired,
  accept: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired
};

export default ConfirmAddressModal;
