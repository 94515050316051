import React from 'react';
import EmptyState from 'components/EmptyState';
import DocumentItem from './DocumentItem';

const DocumentList = ({
  documents,
  applicantId,
  handleDelete,
  handleDownload,
  alertHandler,
  fetchUserHandler,
}) => {
  if (documents.length === 0) {
    return (
      <EmptyState>
        <h3>
          You have not uploaded any documents yet. Use the form below to add a
          new document to your profile.
        </h3>
      </EmptyState>
    );
  }

  return (
    <React.Fragment>
      <div className="card p-4 mb-3 card-shadow">
        <h5 className="mb-3">Current Documents</h5>
        {documents.map(document => (
          <DocumentItem
            document={document}
            applicantId={applicantId}
            handleDelete={handleDelete}
            handleDownload={handleDownload}
            alertHandler={alertHandler}
            fetchUserHandler={fetchUserHandler}
            key={document.id}
          />
        ))}
      </div>
    </React.Fragment>
  );
};

export default DocumentList;
