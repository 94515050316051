import React from 'react';

const EqualHousingIcon = props => (
  <svg viewBox="0 0 6.5 4.8" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>equal-housing-icon</title>
    <g fill={props.color || '#ffffff'}>
      <path
        d="m220.93 123.17h-4.56v-2.17s2.3-1.3 2.3-1.32l2.27 1.31zm-2.21-4.17c-.07 0-3.35 1.88-3.35 1.88v.5h.38v2.41h5.77v-2.4h.33v-.51s-3-1.84-3.15-1.88"
        transform="translate(-215.37 -118.98)"
      />
      <path d="m2.07 2.04h2.49v.43h-2.49z" />
      <path d="m2.08 3.04h2.49v.44h-2.49z" />
    </g>
  </svg>
);

export default EqualHousingIcon;
