import { get } from 'utils/env';

const TOKEN_STORAGE_KEY = 'hhaa';

export function apiURL(pathname, basename) {
  const url = get('api_url');
  basename = basename ? basename : get('basename');

  if (pathname.charAt(0) !== '/') {
    pathname = `/${pathname}`;
  }

  if (basename.charAt(0) !== '/') {
    basename = `/${basename}`;
  }

  return `${url}${basename}${pathname}`;
}

export const getToken = () => {
  let storageMethod = localStorage;
  let JWT = storageMethod.getItem(TOKEN_STORAGE_KEY);
  if (!JWT) {
    storageMethod = sessionStorage;
    JWT = storageMethod.getItem(TOKEN_STORAGE_KEY);
  }
  return {
    token: JSON.parse(JWT),
    storageMethod
  };
};

export const setToken = (storageMethod, headerValues) => {
  storageMethod.setItem(TOKEN_STORAGE_KEY, JSON.stringify(headerValues));
};

export const dropToken = () => {
  localStorage.removeItem(TOKEN_STORAGE_KEY);
  sessionStorage.removeItem(TOKEN_STORAGE_KEY);
};

export const handleJSONResponse = (response, storageMethod) => {
  let json = response.json();
  let { token } = getToken();
  let { headers } = response;

  if (response.status >= 200 && response.status < 300) {
    return json.then(json => {
      let reqHeader = {};

      // Authentication required.
      // Batch requests do not receive a new token. If it hasn't been refreshed, keep the old token
      if (headers.get('uid')) {
        reqHeader = {
          client: headers.get('client'),
          expiry: headers.get('expiry') ? headers.get('expiry') : token.expiry,
          uid: headers.get('uid'),
          'access-token': headers.get('access-token')
            ? headers.get('access-token')
            : token['access-token'],
          'token-type': headers.get('token-type')
        };
      }

      if (storageMethod) {
        setToken(storageMethod, reqHeader);
      }

      return {
        reqHeader,
        ...json
      };
    });
  } else {
    // If logged in and failure provided new token
    if (storageMethod && token) {
      token['access-token'] = headers.get('access-token')
        ? headers.get('access-token')
        : token['access-token'];
      setToken(storageMethod, token);
    }

    return json
      .catch(parseErr => {
        let errObj = {
          errors: [
            {
              title: parseErr.message,
              meta: parseErr
            }
          ]
        };
        throw errObj;
      })
      .then(errJSON => {
        throw errJSON;
      });
  }
};
